<template>
  <div
    :class="['vertical-banner-container', {'clickable': slide.clickable}]"
    :to="slide.to"
    :href="slide.href"
    :data-aid="getBannerAid()"
    :data-banner-id="slide.id"
    :data-wechat-url="slide.wechatMiniProgramUrl"
    :style="{ backgroundImage: 'url(' + imageUrl + ')', paddingLeft: width }"
    @click="!!slide.wechatMiniProgramUrl ? openWechatURL(slide.wechatMiniProgramUrl) : openUrl(slide.href)"
  >
  </div>
</template>

<script>
import { isWechatMiniProgram } from '@/utils/utils'
import utilsMixin from '@/mixins/utilsMixin'

export default {
  name: 'VerticalBanner',
  mixins: [utilsMixin],
  props: {
    width: {
      type: String,
      default: '4px'
    },
    banner: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    isWechatMiniProgram () {
      return isWechatMiniProgram()
    },
    imageUrl () {
      let imageField = 'cover_photo_for_vertical_url'
      if (this.$store.getters['app/isMobile']) {
        imageField = 'mobile_photo_for_vertical_url'
        if (this.$store.getters['app/isTablet']) {
          imageField = 'tablet_photo_for_vertical_url'
        }
      }
      return this.banner[imageField]
    },
    slide () {
      const {
        id,
        landing_page_type: landingPageType,
        wechat_landing_page_url_type: wechatLandingPageType,
        manual_input_url: inputUrl,
        wechat_url: rawWechatUrl,
        wechat_app_id: wechatAppId,
        wechat_path: wechatPath
      } = this.banner

      let url = null
      let wechatUrl = null
      try {
        if (landingPageType === 'MANUAL_INPUT_URL') {
          url = inputUrl ? new URL(inputUrl) : null
          if (wechatLandingPageType !== 'DIRECT_TO_WECHAT') {
            wechatUrl = rawWechatUrl ? new URL(rawWechatUrl) : null
          }
        }
      } catch (error) {
        console.log('fail to parse url', inputUrl)
      }
      console.log('slide url', url)

      const { lang } = this.$route.params
      const to = false
      let href = landingPageType === 'MANUAL_INPUT_URL' ? inputUrl : null
      let wechatMiniProgramUrl = null

      if (isWechatMiniProgram()) {
        href = false
        if (wechatLandingPageType === 'DIRECT_TO_WECHAT' && wechatAppId && wechatPath) {
          wechatMiniProgramUrl = '/pages/jump/jump?appId=' +
            encodeURIComponent(wechatAppId) + '&path=' + encodeURIComponent(wechatPath) + '&lang=' + lang
        } else if (wechatUrl) {
          if (rawWechatUrl.includes(process.env.VUE_APP_WALLET_URL)) {
            href = rawWechatUrl
          } else {
            wechatMiniProgramUrl = rawWechatUrl
          }
        }
      }

      return {
        id,
        href,
        to,
        wechatMiniProgramUrl,
        clickable: wechatMiniProgramUrl || href
      }
    }
  },
  methods: {
    getBannerAid () {
      let bannerAid = ''
      if (this.$route.name === 'my-wallet') {
        bannerAid = 'home_banner_click'
      } else {
        bannerAid = 'home_banner_click'
      }
      return bannerAid
    },
    openWechatURL (wechatMiniProgramUrl) {
      console.log('@49', wechatMiniProgramUrl)
      if (wechatMiniProgramUrl) {
        if (wechatMiniProgramUrl.startsWith('http')) {
          this.$clipboard(wechatMiniProgramUrl)
          this.$message.closeAll()
          this.showLinkCopySuccess()
        } else {
          window.wx.miniProgram.navigateTo({ url: wechatMiniProgramUrl })
        }
      }
    },
    openUrl (url) {
      if (url) {
        if (url.includes(process.env.VUE_APP_WALLET_URL)) {
          window.location.href = url
        } else {
          window.open(url, '_blank').focus()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.vertical-banner-container {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  cursor: default !important;
}
.vertical-banner-container.clickable {
  cursor: pointer !important;
}
</style>
