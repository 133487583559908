import { render, staticRenderFns } from "./CouponDetail.vue?vue&type=template&id=15dcf931&scoped=true&"
import script from "./CouponDetail.vue?vue&type=script&lang=js&"
export * from "./CouponDetail.vue?vue&type=script&lang=js&"
import style0 from "./CouponDetail.vue?vue&type=style&index=0&id=15dcf931&lang=scss&scoped=true&"
import style1 from "./CouponDetail.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15dcf931",
  null
  
)

export default component.exports