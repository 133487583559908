<template>
  <div
    v-if="isNoData"
    class="no-data"
  >
    <not-find />
  </div>
  <div v-else class="coupon-detail-main-container">

    <div class="coupon-detail-container">
      <div class="coupon-detail-column">
        <goodie-bag-banner
          v-if="$store.getters['app/isMobile'] && isGoodieBagCampagin"
          class="goodie-bag-credits-info"
          :recordid="recordId"
          :type="isGoodieBagGift?'GIFT':isGoodieBagVoucher?'Voucher':''"
          :slotid="slotId"
        />
        <div v-if="$store.getters['app/isMobile'] && specialCouponUserInfo" class="coupon-block special-use-coupon">
          <div class="special-use-coupon-tip">{{ $t('myWalletValueCoupon') }}</div>
          <div class="special-use-coupon-info">{{ specialCouponUserInfo }}</div>
        </div>
        <div class="coupon-block">
          <div v-if="isShowCouponIndicator" class="coupon-indicator" :style="{ backgroundColor: couponIndicatorColor }">{{ couponIndicatorText }}</div>
          <div
            class="carousel-banner"
            :class="{'goodie-bag': isGoodieBagCampagin}"
          >
            <div v-show="isShowQrCanvas" class="qr-canvas-contain">
              <div class="qr-canvas-title">{{ isTicket?$t('myWalletUseTicket'): $t('myWalletUseCoupon') }}</div>
              <canvas id="qr-canvas" />
              <div v-if="srk" class="qr-canvas-srk" :style="{ width: qrCodeWidth + 'px' }">{{ srk }}</div>
            </div>
            <div v-show="isShowQrCanvas" class="qr-canvas-footer">{{ $t('myWalletUsingQRCodetip') }}</div>
            <div v-if="$store.getters['app/isMobile'] && couponId && (canShowCopyCodeButton || isPartnerCode)" class="qr-canvas-contain coupon-detail-mobile-copy-container" :class="{'partner-qr-bar-code':isPartnerCode }">
              <div class="coupon-detail-mobile-button-container">
                <div v-if="couponCodeFormat==='COUPON_CODE'" class="coupon-detail-mobile-button-tip">
                  {{ $t('myWalletUseCouponDiscount') }}
                </div>
                <div v-else-if="isPartnerCode" class="coupon-detail-mobile-button-tip">
                  {{ isTicket? $t('showTicketCodeToStaff') : $t('showCodeToStaff') }}
                </div>
                <div v-else class="coupon-detail-mobile-button-tip">
                  {{ $t('myWalletUseCouponDiscountLink') }}
                </div>
                <div class="coupon-detail-mobile-button-text">
                  <div v-if="couponCodeFormat==='COUPON_CODE' || isPartnerCode" class="coupon-detail-mobile-copy-button-text">
                    <div class="coupon-detail-mobile-code mobile" :class="{'partner-bar-code': isShowPartnerBarCode}">
                      <canvas v-if="isShowPartnerQRCode" id="partner-qr-code-img" />
                      <barcode v-else-if="isShowPartnerBarCode" :value="barcodeValue" :options="barcodeOption" />
                      <div v-else class="code">
                        {{ couponCode }}
                      </div>
                      <div v-if="isShowPartnerBarCode" class="rotation-reminder">
                        <img src="@/assets/images/rotation-hint.png">
                        <p>{{ $t('mycouponfullWindowBarcode') }}</p>
                      </div>
                    </div>
                    <div v-if="displayCouponCode" class="partner-qr-bar-code-code">{{ barcodeValue }}</div>
                    <div v-else-if="isPartnerCode" class="partner-qr-bar-code-reference-id">{{ $t('partnerCodeReferenceId') }} {{ referenceId }}</div>
                    <div v-else class="coupon-detail-mobile-button copy-code secondary-button primary-button" @click="copyCouponCode">{{ $t('myWalletCopyCode') }}</div>
                    <div v-if="couponCodeUrl" class="coupon-detail-mobile-button shop-now secondary-button" @click="useCouponCodeUrl()">{{ couponShopNowName }}</div>
                    <!--<div v-if="couponCodeUrl" class="shop-now-url">{{ couponCodeUrl }}</div>-->
                  </div>
                  <div v-else-if="couponCodeFormat==='COUPON_URL'" class="coupon-detail-mobile-copy-button-text">
                    <div v-if="couponCodeUrl" class="coupon-detail-mobile-url">
                      <div>
                        {{ couponCodeUrl }}
                      </div>
                    </div>
                    <div v-else class="coupon-detail-mobile-url">
                      <div>
                        {{ couponCode }}
                      </div>
                    </div>
                    <div class="coupon-detail-mobile-button copy-code secondary-button primary-button" @click="useCouponCodeUrl()">{{ couponShopNowName }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div style="position: relative;">
              <agile
                v-if="scrollphotos && scrollphotos.length"
                :slides-to-show="1"
                class="carousel detail"
                :autoplay="scrollphotos.length>1"
                :nav-buttons="scrollphotos.length && scrollphotos.length>1"
                :dots="scrollphotos.length && scrollphotos.length>1"
                :infinite="scrollphotos.length && scrollphotos.length>1"
              >
                <div v-for="(item, index) in scrollphotos" :key="index" class="slide">
                  <div class="slide-image" :style="{backgroundImage: 'url('+item+')'}" />
                </div>
                <template slot="prevButton"><div class="action action-prev"><img :data-aid="campaignId?'offer_detail_image_view':'coupon_detail_image_view'" src="/img/carousel_left.png"></div></template>
                <template slot="nextButton"><div class="action action-next"><img :data-aid="campaignId?'offer_detail_image_view':'coupon_detail_image_view'" src="/img/carousel_right.png"></div></template>
              </agile>
              <div v-if="offerTypeLabel && offerTypeLabel.name" class="coupon-offer-labels">
                <div class="coupon-offer-label">
                  <img src="@/assets/images/offer-label-shadow.png" class="coupon-offer-label-shadow">
                  <div class="coupon-offer-label-content">
                    <div class="coupon-offer-label-font">
                      <div class="coupon-offer-label-font-item">{{ offerTypeLabel.name }}</div>
                    </div>
                    <div class="coupon-offer-label-right-image" />
                  </div>
                </div>
              </div>
              <div v-if="isShowLowStockLabel || isShowOutOfStockLabel" class="low-label" :class="{'plural': scrollphotos.length>1}">
                <div v-if="isShowLowStockLabel">{{ $t('campaignLow') }}</div>
                <div v-else-if="isShowOutOfStockLabel">{{ $t('campaignOutOf') }}</div>
              </div>
            </div>
          </div>

          <div v-if="!$store.getters['app/isMobile'] && couponId && isShowCopyCodeInfo" class="qr-canvas-contain coupon-detail-mobile-copy-container">
            <div class="coupon-detail-mobile-button-container">
              <div class="coupon-detail-mobile-button-tip">
                {{ $t('myWalletUseCouponDiscount') }}
              </div>
              <div class="coupon-detail-mobile-button-text">
                <div class="coupon-detail-mobile-copy-button-text">
                  <div class="coupon-detail-mobile-code">
                    {{ couponCode }}
                  </div>
                  <div class="coupon-detail-mobile-button primary-button" @click="copyCouponCode">{{ $t('myWalletCopyCode') }}</div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="couponData && (isRedeemed || isExpired)" class="coupon-redeem-tip-container">
            <span v-if="isRedeemed" :class="['coupon-redeem-tip', {'ticket-redeem-tip': isTicket}]">{{ $t('myWalletRedeemed') }}</span>
            <span v-if="!isRedeemed && isExpired" :class="['coupon-redeem-tip', {'ticket-redeem-tip': isTicket}]">{{ $t('myWalletExpired') }}</span>
          </div>

          <div v-if="isGoodieBagCampagin || isStarred || isCouponCampagin" class="campaign-tag">
            <div v-if="isStarred" class="tag starred">{{ $t('campaignSTARRED') }}</div>
            <div v-if="couponDisplayIn==='VOUCHER_SECTION'" class="tag">{{ $t('goodiebagCashVouchers') }}</div>
            <div v-if="couponDisplayIn==='LIMITED_OFFER_SECTION'" class="tag gift">{{ $t('goodiebagLimitedOffers') }}</div>
            <div v-if="couponDisplayIn==='COUPON_SECTION'" class="tag gift">{{ $t('myWalletCoupons') }}</div>
            <div v-if="couponDisplayIn==='TICKET_SECTION'" class="tag gift ticket">{{ $t('myWalletTickets') }}</div>
          </div>

          <div class="coupon-title">
            {{ name }}
          </div>

          <div class="coupon-expiration">
            <div>{{ validDate }}</div>
            <div v-if="usedStore">{{ usedStore }}</div>
          </div>

          <div v-if="isRedeemed && referenceId" class="reference-id-contain">
            <div style="margin-right: 3px">{{ $t('referenceID') }}</div>
            <div>{{ referenceId }}</div>
          </div>

          <div v-if="(isNeedShowLimitText || showStatementWordingForCountryCodeLimit)" class="goodie-bag-limit">
            <div v-if="isNeedShowLimitText" class="contain">
              <img
                style="margin-top:3px"
                width="16px"
                srcset="/img/user-limit.1x.png, /img/user-limit.2x.png 2x, /img/user-limit.3x.png 3x"
                src="/img/user-limit.2x.png"
              >
              <div class="limit-text">
                <!---<div v-html="$t('goodiebagCouponDownloadLimit', {limit: goodiePerHeadLimit})" />-->
                <!--<div v-html="$t('goodiebagOwnedCouponLimit', {limit: goodiePerHeadOwnLimit})" />-->

                <div v-if="goodiePerHeadLimit && !goodiePerHeadOwnLimit" v-html="$t('goodiebagCouponLimitMix1', {limit1: goodiePerHeadLimit})" />
                <div v-if="!goodiePerHeadLimit && goodiePerHeadOwnLimit" v-html="$t('goodiebagCouponLimitMix3', {limit3: goodiePerHeadOwnLimit})" />
                <div v-if="goodiePerHeadLimit && goodiePerHeadOwnLimit" v-html="$t('goodiebagCouponLimitMix1And3', {limit1: goodiePerHeadLimit, limit3: goodiePerHeadOwnLimit})" />

                <div v-if="isStarred" v-html="$t('goodiebagOwnedCouponLimitSTARRED', {limit: goodiePerHeadOwnStarredLimit})" />
                <!--<div v-html="$t('goodiebagOnlyAcquire', {limit: goodiePerHeadLimit})" />-->
              </div>
            </div>
            <div v-if="(showStatementWordingForCountryCodeLimit && statementWordingForCountryCodeLimit)" class="contain">
              <img
                style="margin-top:3px"
                width="16px"
                srcset="/img/countryLimit.1x.png, /img/countryLimit.2x.png 2x, /img/countryLimit.3x.png 3x"
                src="/img/countryLimit.2x.png"
              >
              <div class="limit-text">
                {{ statementWordingForCountryCodeLimit }}
              </div>
            </div>
          </div>

          <div v-if="(shortDescription || brandName)" class="coupon-description-container">
            <div v-if="shortDescription" class="coupon-descripton" :class="{'gift': isGoodieBagGift, 'voucher': isGoodieBagVoucher}">
              <div class="coupon-description-content toastui-editor-contents" v-html="shortDescription" />
            </div>
            <div v-if="brandName" class="brand-container" :class="{'brand-container-pointer': brandDescription}" :data-aid="campaignId?'offer_detail_brand_more':'coupon_detail_brand_more'" @click="toggleBrandDescription">
              <div class="brand-row">
                <div class="brand-container">
                  <div class="brand-logo" :style="{backgroundImage: 'url('+brandLogoUrl+')'}" />
                  <div class="brand-name">{{ brandName }}</div>
                </div>
                <div v-if="brandDescription" class="collpase-arrow">
                  <img v-if="!isShowBrandDescription" class="brand-arrow-icon" src="@/assets/images/arrow-down.png">
                  <img v-else class="brand-arrow-icon" src="@/assets/images/arrow-up.png">
                </div>
              </div>
              <div v-if="isShowBrandDescription" class="brand-description toastui-editor-contents" v-html="brandDescription" />
            </div>

          </div>
        </div>
        <div v-if="applicableStoresList && applicableStoresList.length" class="coupon-block coupon-applicable-stores" :data-aid="campaignId?'offer_detail_applicable_stores_more':'coupon_detail_applicable_stores_more'" @click="openApplicableStores">
          <div class="brand-name">{{ isTicket?$t('campaignEventLocation'): $t('campaignApplicableStores') }}</div>
          <div class="collpase-arrow"><img class="store-arrow-icon" src="@/assets/images/arrow-right.png"></div>
        </div>
        <div v-if="offerDetailTitle || offerDetailContent" :class="{'coupon-block': true, 'is-collapsible': true, 'is-collapsed':false}">
          <div class="usage-block">
            <div v-if="offerDetailTitle" class="usage-title">{{ offerDetailTitle }}</div>
            <div v-else class="usage-title">{{ $t('offerdetailsectionOfferDetailsection') }}</div>
            <div class="usage-detail toastui-editor-contents" v-html="offerDetailContent" />
          </div>
          <div class="collpase-button-container">
            <div class="see-more-button">{{ $t('myWalletSeeMore') }}</div>
          </div>
        </div>
        <div v-if="detailSectionTitle || detailSectionContent" :class="{'coupon-block': true, 'is-collapsible': true, 'is-collapsed':false}">
          <div class="usage-block">
            <div v-if="detailSectionTitle" class="usage-title">{{ detailSectionTitle }}</div>
            <div v-else class="usage-title">{{ $t('detailDetail') }}</div>
            <div class="usage-detail toastui-editor-contents" v-html="detailSectionContent" />
          </div>
          <div class="collpase-button-container">
            <div class="see-more-button">{{ $t('myWalletSeeMore') }}</div>
          </div>
        </div>

        <div v-if="tourInfo && tourInfo.length" class="coupon-block tours-card-block">
          <div class="usage-block">
            <div v-if="tourCardTitle" class="usage-title">{{ tourCardTitle }}</div>
            <div v-else class="usage-title">{{ $t('tourcardSectionTourCardSection') }}</div>
            <div class="usage-detail">
              <agile
                v-if="tourInfo && tourInfo.length"
                :slides-to-show="1"
                :infinite="false"
                :autoplay="false"
                :nav-buttons="!$store.getters['app/isMobile'] && tourInfo && tourInfo.length > 2"
                class="carousel tour-carousel"
                :dots="($store.getters['app/isMobile'] && tourInfo.length >=2) || (!$store.getters['app/isMobile'] && tourInfo.length >=3)"
                @after-change="showCurrentSlide($event); analytics($event)"
              >
                <template v-if="$store.getters['app/isMobile']">
                  <div v-for="(item, index) in tourInfo" :key="index" class="card-outer">
                    <tour-card :tourinfo="item" :number="index" />
                  </div>
                </template>

                <template v-else>
                  <div v-for="(item, index) in getTourInfoIn2()" :key="index" class="card-outer">
                    <tour-card :tourinfo="item[0]" :number="index*2" />
                    <tour-card v-if="item.length > 1 && item[1]" :tourinfo="item[1]" :number="index*2+1" />
                  </div>
                </template>

                <div v-if="tourInfo.length === 1 && !$store.getters['app/isMobile']" />
                <template slot="prevButton"><div v-show="isShowTourPreButton" class="action action-prev"><img src="/img/carousel_left.png"></div></template>
                <template slot="nextButton"><div v-show="isShowTourNextButton" class="action action-next"><img src="/img/carousel_right.png"></div></template>
              </agile>
            </div>
          </div>
        </div>

        <div v-if="instructionContent" :class="{'coupon-block': true, 'is-collapsible': true, 'is-collapsed':false}">
          <div class="usage-block">
            <div v-if="instructionContent" class="usage-title">{{ instructionTitle ? instructionTitle : $t('instructionsectionInstructionSection') }}</div>
            <div v-if="instructionContent" class="usage-detail toastui-editor-contents" v-html="instructionContent" />
          </div>
          <div class="collpase-button-container">
            <div class="see-more-button">{{ $t('myWalletSeeMore') }}</div>
          </div>
        </div>

        <div v-if="merchantTermsContent" :class="{'coupon-block': true, 'is-collapsible': true, 'is-collapsed':false}">
          <div class="usage-block">
            <div v-if="merchantTermsContent" class="usage-title">{{ merchantTermsTitle ? merchantTermsTitle : $t('merchantTerms&ConditionsMTConditions') }}</div>
            <div v-if="merchantTermsContent" class="usage-detail toastui-editor-contents" v-html="merchantTermsContent" />
          </div>
          <div class="collpase-button-container">
            <div class="see-more-button" :data-aid="campaignId?'offer_detail_merchant_terms_more':'coupon_detail_merchant_terms_more'">{{ $t('myWalletSeeMore') }}</div>
          </div>
        </div>

        <div v-if="generalTermsTitle || generalTermsContent" :class="{'coupon-block': true, 'is-collapsible': true, 'is-collapsed':false}">
          <div class="usage-block">
            <div v-if="generalTermsTitle" class="usage-title">{{ generalTermsTitle }}</div>
            <div v-else class="usage-title">{{ $t('genericTerms&ConditionsGTConditions') }}</div>
            <div class="usage-detail toastui-editor-contents" v-html="generalTermsContent" />
          </div>
          <div class="collpase-button-container">
            <div class="see-more-button" :data-aid="campaignId?'offer_detail_general_terms_more':'coupon_detail_general_terms_more'">{{ $t('myWalletSeeMore') }}</div>
          </div>
        </div>

      </div>
      <!-- start of new column -->

      <div class="qr-code-column">
        <div>

          <div v-if="logoLabels && logoLabels.length" class="label-container">
            <a v-for="item in logoLabels" :key="item.id" class="label-row" :href="item.logo_label_url" :class="{'no-logo-label-url': !item.logo_label_url}">
              <div class="label-icon" :style="{ backgroundImage:'url(' + item.icon_url +')'}" />
              <div class="label-description">{{ item.name }}</div>
            </a>
          </div>

          <goodie-bag-banner
            v-if="!$store.getters['app/isMobile'] && isGoodieBagCampagin"
            class="goodie-bag-credits-info goodie-bag-campaign-desktop"
            :recordid="recordId"
            :type="isGoodieBagGift?'GIFT':isGoodieBagVoucher?'Voucher':''"
            :slotid="slotId"
          />

          <div
            v-if="campaignId && !$store.getters['app/isMobile'] &&
              (isGoodieBagGift || isGoodieBagVoucher) && isCampaignAvailable"
            class="web-get-coupon-container goodie-bag"
          >
            <div v-if="(!isUnlimitedReward || (isUnlimitedReward && goodiePerHeadDownloadLimit>goodiePerHeadDownloadLimitEachTime)) && isGoodieBagVoucher" class="title">{{ $t('goodiebagRequirecredits') }}</div>
            <div v-if="isGoodieBagVoucher || goodiePerHeadDownloadLimit>goodiePerHeadDownloadLimitEachTime" class="credits-count desktop">
              <div v-if="isGoodieBagVoucher" class="num">{{ goodieBagCost }}</div>
              <div v-else-if="isGoodieBagGift" class="title">{{ $t('goodiebagSelectQuantily') }}</div>
              <div v-if="goodiePerHeadDownloadLimit>goodiePerHeadDownloadLimitEachTime" class="credits">
                <div class="reduce" :class="{'disable': requireAmount<=goodiePerHeadDownloadLimitEachTime}" @click="goodieBagReduce">-</div>
                <div class="count">{{ requireAmount }}</div>
                <div class="add" :class="{'disable': isAddDisable()}" @click="goodieBagAdd">+</div>
              </div>
            </div>
            <hr v-if="(!isUnlimitedReward && isGoodieBagVoucher) || goodiePerHeadDownloadLimit>goodiePerHeadDownloadLimitEachTime">
            <div v-if="goodieBagAvailableTime" :class="{'web-get-coupon-button':true, 'primary-button': !goodieBagAvailableTime, 'coupon-unavailable':goodieBagAvailableTime, 'get-type-1': true}">{{ $t('goodiebagAvailableOn', {date: goodieBagAvailableTime}) }}</div>
            <div v-else-if="hasDisabledGoodibagAcquire" class="web-get-coupon-button coupon-unavailable get-type-10">{{ $t('goodiebagAcquireNow') }}</div>
            <div v-else :class="{'web-get-coupon-button':true, 'primary-button': true, 'get-type-16':true}" data-aid="offer_detail_get_coupon" @click="acquireGoodieBag">{{ $t('goodiebagAcquireNow') }}</div>
            <div v-if="getCouponStockMessage" class="starred-full">{{ getCouponStockMessage }}</div>
            <div class="web-get-coupon-title">{{ $t('campaignUesCoupon') }}</div>
          </div>

          <div v-if="campaignId && !$store.getters['app/isMobile'] && isGeneralCampaign && template.landing_page_button_name && template.landing_page_url" class="web-get-coupon-container">
            <div class="web-get-coupon-title">{{ $t('campaignGeneralMessagePrompt') }}</div>
            <a class="landing-page" data-aid="offer_detail_get_coupon" :href="template.landing_page_url" @click="bookNowClick"><div :class="{'web-get-coupon-button':true, 'primary-button': true, 'get-type-17': true}">{{ template.landing_page_button_name }}</div></a>
          </div>

          <div v-if="campaignId && !$store.getters['app/isMobile'] && !isGeneralCampaign && !(isGoodieBagGift || isGoodieBagVoucher) && isCampaignAvailable" class="web-get-coupon-container">
            <div class="web-get-coupon-title">{{ $t('campaignUesCoupon') }}</div>
            <div v-if="!campaignCanGetCoupon || getCouponStockMessage" class="web-get-coupon-button primary-button coupon-unavailable get-type-2">{{ campaignGetCouponText }}</div>
            <div v-else :class="{'web-get-coupon-button':true, 'primary-button': campaignCanGetCoupon, 'get-type-18':true}" data-aid="offer_detail_get_coupon" @click="getCoupon">{{ campaignGetCouponText }}</div>
            <div v-if="getCouponStockMessage" class="starred-full add-to-wallet">{{ getCouponStockMessage }}</div>
          </div>

          <div v-if="!$store.getters['app/isMobile'] && couponId && !isTicket && !isPartnerCodeCoupon" class="qr-container is-hidden-mobile" @click="showMobileQrCodeImageAlert">
            <div class="qr-code">
              <canvas id="mobile-url-qr-code" />
            </div>
            <div class="qr-description">
              <img
                width="11px"
                srcset="/img/magnifier.1x.png, /img/magnifier.2x.png 2x, /img/magnifier.3x.png 3x"
                src="/img/magnifier.2x.png"
              >
              {{ isTicket ? $t('myWalletUserExperienceTicketMessage'): $t('myWalletUserExperienceMessage') }}
            </div>
          </div>
          <div v-if="!$store.getters['app/isMobile'] && couponId && isTicket" class="qr-ticket-container is-hidden-mobile show-web-ticket">
            {{ $t('acquireUseTicketOnMobile') }}
          </div>

          <div v-if="couponId && (canShowCopyCodeButton || canShowUseCouponButton|| canSubmitSurvey || isPartnerCode)" class="coupon-detail-button-container is-hidden-mobile" :class="{'partner-qr-bar-code': isPartnerCode}">
            <div v-if="(canShowUseCouponButton || canShowCopyCodeButton) && couponCodeFormat==='COUPON_CODE'" class="coupon-detail-button-tip">
              {{ $t('myWalletUseCouponDiscount') }}
            </div>
            <div v-if="(canShowUseCouponButton || canShowCopyCodeButton) && couponCodeFormat==='COUPON_URL'" class="coupon-detail-button-tip">
              {{ $t('myWalletUseCouponDiscountLink') }}
            </div>
            <div v-if="(isPartnerCode || canShowUseCouponButton) && (couponCodeFormat==='PARTNER_QR_CODE' || couponCodeFormat==='PARTNER_BARCODE')" class="coupon-detail-button-tip code-to-staff-text">
              {{ isTicket? $t('showTicketCodeToStaff') : $t('showCodeToStaff') }}
            </div>
            <div class="coupon-detail-mobile-button-text">
              <div v-if="canShowUseCouponButton" class="coupon-detail-mobile-use-button-text">
                <div class="coupon-detail-mobile-button primary-button" :class="{'coupon-unavailable': !isInUsedTime, 'get-type-3':true}" :style="{padding: !isInUsedTime ? isUnavailableButtonPadding : '0px'}" data-aid="coupon_detail_use_coupon_click" @click="useCoupon">{{ isInUsedTime ? isTicket?$t('homeUseTicket'): $t('homeUseCoupon') : $t('homeUseCouponNotAvailable') }}</div>
              </div>
              <div v-if="canShowCopyCodeButton || canSubmitSurvey || isPartnerCode" class="coupon-detail-mobile-copy-button-text">
                <div v-if="canShowCopyCodeButton || isPartnerCode" class="coupon-detail-code-contain">
                  <div v-if="couponCodeFormat==='COUPON_CODE'|| isPartnerCode" class="coupon-detail-mobile-copy-button-text">
                    <div v-if="isPartnerCode" class="coupon-detail-code coupon-partner-qr-code" :class="{'partner-bar-code': isShowPartnerBarCode}">
                      <canvas v-show="isShowPartnerQRCode" id="partner-qr-code-img" />
                      <barcode v-if="isShowPartnerBarCode" :value="barcodeValue" :options="barcodeOption" />
                    </div>
                    <div v-else class="coupon-detail-code">
                      <div class="code">{{ couponCode }}</div>
                    </div>
                    <div v-if="displayCouponCode" class="partner-qr-bar-code-code">{{ barcodeValue }}</div>
                    <div v-else-if="isPartnerCode" class="partner-qr-bar-code-reference-id">{{ $t('partnerCodeReferenceId') }} {{ referenceId }}</div>
                    <div v-else class="coupon-detail-button copy-codes primary-button" @click="copyCouponCode">{{ $t('myWalletCopyCode') }}</div>
                    <div v-if="isShowPartnerBarCode" class="partner-bar-code-tip" @click="clickToShowFullPartnerBarCodeDialogDesktop" v-html="$t('tipShowFullBarCode')" />
                    <div v-if="couponCodeUrl" class="coupon-detail-button shop-now secondary-button" @click="useCouponCodeUrl()">{{ couponShopNowName }}</div>
                    <!--<div v-if="couponCodeUrl" class="shop-now-url">{{ couponCodeUrl }}</div>-->
                  </div>
                  <div v-else-if="couponCodeFormat==='COUPON_URL'" class="coupon-detail-mobile-copy-button-text">
                    <div v-if="couponCodeUrl" class="coupon-detail-url">
                      <div>
                        {{ couponCodeUrl }}
                      </div>
                    </div>
                    <div v-else class="coupon-detail-url">
                      <div>
                        {{ couponCode }}
                      </div>
                    </div>
                    <div class="coupon-detail-button copy-code-single primary-button" @click="useCouponCodeUrl()">{{ couponShopNowName }}</div>
                  </div>
                </div>
                <div v-if="canSubmitSurvey" class="copy-code-redeemed coupon-detail-mobile-button primary-button" data-aid="coupon_detail_redeemed_take_survey" @click="takeSurvey">{{ $t('myWalletTakeSurvey') }}</div>
              </div>
            </div>
          </div>
          <div v-if="showAvalibleTip && !isTicket" class="qr-container coupon-detail-available">
            <img src="@/assets/images/question-mark.png">
            <span>
              {{ isTicket ? $t('myWalletTicketAvailableTime') : $t('myWalletAvailableTime') }} {{ showAvalibleTip }} {{ $t('myWalletAvailableTimeSuffix') }}
            </span>
          </div>

          <div v-if="campaignId && !isMiniProgram() && !isGoodieBagCampagin && !this.$store.getters['auth/isTestUser']" class="share-container">
            <div class="share-title">{{ $t('campaignShare') }}</div>
            <div>
              <!-- TODO change url and meta-->
              <ShareNetwork
                network="facebook"
                :url="shareUrl"
                quote=""
                hashtags=""
                class="share-button"
                title=""
                :data-aid="campaignId?'offer_detail_share_facebook':''"
              >
                <img src="@/assets/images/facekbook-icon.png">
              </ShareNetwork>

              <ShareNetwork
                network="twitter"
                :url="shareUrl"
                quote=""
                hashtags=""
                class="share-button"
                :title="twitterShareContent"
                :data-aid="campaignId?'offer_detail_share_twitter':''"
              >
                <img src="@/assets/images/twitter-icon.png">
              </ShareNetwork>

              <a class="share-button" :data-aid="campaignId?'offer_detail_share_copy_link':''" @click="shareCopyLink">
                <img
                  srcset="/img/link-icon.1x.png, /img/link-icon.2x.png 2x, /img/link-icon.3x.png 3x"
                  src="/img/link-icon.2x.png"
                >
              </a>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div
      v-if="couponId && ((!isUnavailable && !isCouponRedeemOrExpired) || isUnavailable || isRedeemed)"
      class="sticky-coupon-footer"
    >
      <!--remove || isMerchantAppScanCouponQrCode check -->
      <div v-if="(!isUnavailable) && !isCouponRedeemOrExpired " class="sticky-coupon-footer-button">
        <div v-show="isOpeningCamera" class="sticky-footer-button">
          <div class="loading-dot" style="background-image:url(/img/loading-dot.gif);width:100px" />
        </div>
        <div v-show="!isOpeningCamera && couponData.within_used_time !== undefined" class="sticky-footer-button" :class="{'coupon-unavailable': (isUnavailable && !isMerchantAppScanCouponQrCode) || !isInUsedTime, 'get-type-3':true}" data-aid="coupon_detail_use_coupon_click" @click="useCoupon()">
          <div>{{ !isInUsedTime ? $t('homeUseCouponNotAvailable') : isTicket?$t('homeUseTicket'): $t('homeUseCoupon') }}</div>
        </div>
      </div>
      <div v-else-if="isUnavailable" class="sticky-coupon-footer-button" :class="{'coupon-unavailable': isUnavailable, 'get-type-4': true}">
        <div class="sticky-footer-button get-type-11"><div>{{ isTicket? $t('myWalletTicketAvailableTime') : $t('myWalletAvailableTime') }} {{ showAvalibleTip }} {{ $t('myWalletAvailableTimeSuffix') }}</div></div>
      </div>
      <div v-if="isRedeemed && !hideRedeemeButton" class="sticky-coupon-footer-button sticky-coupon-footer-survey-buttons">
        <div v-if="canSubmitSurvey" data-aid="coupon_detail_redeemed_take_survey" class="sticky-footer-take-survey-button primary-button" @click="takeSurvey">{{ $t('myWalletSuccessfullyRedeemedTakeSuvey') }}</div>
        <div v-if="canShowViewBarCode" class="sticky-footer-take-survey-button primary-button sticky-footer-view-code-button" @click="showBarCodeDialog()">{{ $t('myWalletViewCode') }}</div>
        <div v-if="isMerchantAppScanCouponQrCode" class="sticky-footer-take-survey-button primary-button sticky-footer-view-code-button" @click="showQrCodeImg()">{{ isTicket? $t('myWalletViewTicketCode'): $t('myWalletViewCouponCode') }}</div>
      </div>
    </div>

    <div
      v-if="campaignId &&
        ((isGoodieBagVoucher && goodiePerHeadDownloadLimit===goodiePerHeadDownloadLimitEachTime && !goodieBagAvailableTime)
          || isGoodieBagGift )&& isCampaignAvailable"
      class="sticky-coupon-footer sticky-campagin-button goodie-bag-footer-single"
      :class="{'gift': isGoodieBagGift}"
    >
      <div class="goodie-bag-footer-single-top">
        <div v-if="!isUnlimitedReward && isGoodieBagVoucher" class="left">
          <div class="title">{{ $t('goodiebagRequirecredits') }}</div>
          <div class="num">{{ goodieBagCost }}</div>
        </div>
        <div v-else-if="isGoodieBagVoucher" class="left" />
        <div v-if="isGoodieBagGift && !goodieBagAvailableTime" class="left goodie-bag-footer-gift">
          <div
            v-if="goodiePerHeadDownloadLimit>goodiePerHeadDownloadLimitEachTime"
            class="title"
            :class="{'single': goodiePerHeadDownloadLimit==goodiePerHeadDownloadLimitEachTime}"
          >{{ $t('goodiebagSelectQuantily') }}</div>
          <div v-if="goodiePerHeadDownloadLimit>goodiePerHeadDownloadLimitEachTime" class="credits-count gift">
            <div class="reduce" :class="{'disable': requireAmount<=goodiePerHeadDownloadLimitEachTime}" @click="goodieBagReduce">-</div>
            <div class="count">{{ requireAmount }}</div>
            <div class="add" :class="{'disable': isAddDisable()}" @click="goodieBagAdd">+</div>
          </div>
        </div>
        <div v-if="!campaignData || !goodiebagRecordDetail || goodieBagAvailableTime" class="sticky-footer-button get-type-12" data-aid="offer_detail_get_coupon">{{ $t('goodiebagAvailableOn', {date: goodieBagAvailableTime}) }}</div>
        <div v-else-if="hasDisabledGoodibagAcquire" class="sticky-footer-button coupon-unavailable get-type-5" data-aid="offer_detail_get_coupon">{{ $t('goodiebagAcquireNow') }}</div>
        <div v-else class="sticky-footer-button get-type-13" data-aid="offer_detail_get_coupon" @click="acquireGoodieBag">{{ $t('goodiebagAcquireNow') }}</div>
      </div>
      <div v-if="getCouponStockMessage" class="starred-full">{{ getCouponStockMessage }}</div>
    </div>

    <div v-else-if="campaignId && isGoodieBagVoucher && isCampaignAvailable" class="sticky-coupon-footer sticky-campagin-button goodie-bag-footer">
      <div class="require">
        <div class="left">
          <div class="title">{{ $t('goodiebagRequirecredits') }}</div>
          <div class="num">{{ goodieBagCost }}</div>
        </div>
        <div v-if="goodiePerHeadDownloadLimit>goodiePerHeadDownloadLimitEachTime" class="right">
          <div class="reduce" :class="{'disable': requireAmount<=goodiePerHeadDownloadLimitEachTime}" @click="goodieBagReduce">-</div>
          <div class="count">{{ requireAmount }}</div>
          <div class="add" :class="{'disable': isAddDisable()}" @click="goodieBagAdd">+</div>
        </div>
      </div>
      <div v-if="!campaignData || !goodiebagRecordDetail || goodieBagAvailableTime" class="sticky-footer-button primary-button coupon-unavailable get-type-6" data-aid="offer_detail_get_coupon">{{ $t('goodiebagAvailableOn', {date: goodieBagAvailableTime}) }}</div>
      <div v-else-if="hasDisabledGoodibagAcquire" class="sticky-footer-button  primary-button coupon-unavailable get-type-7" data-aid="offer_detail_get_coupon">{{ $t('goodiebagAcquireNow') }}</div>
      <div v-else class="sticky-footer-button primary-button get-type-14" data-aid="offer_detail_get_coupon" @click="acquireGoodieBag">{{ $t('goodiebagAcquireNow') }}</div>
      <div v-if="getCouponStockMessage" class="starred-full">{{ getCouponStockMessage }}</div>
    </div>

    <div v-else-if="campaignId && !isGeneralCampaign && isCampaignAvailable" class="sticky-coupon-footer sticky-campagin-button">
      <div v-if="!campaignCanGetCoupon || getCouponStockMessage" class="sticky-footer-button primary-button get-coupon coupon-unavailable get-type-8">{{ campaignGetCouponText }}</div>
      <div v-else :class="{'sticky-footer-button': true, 'primary-button': true, 'get-coupon':true, 'get-type-15': true}" data-aid="offer_detail_get_coupon" @click="getCoupon">{{ campaignGetCouponText }}</div>
      <div v-if="getCouponStockMessage" class="starred-full">{{ getCouponStockMessage }}</div>
    </div>

    <div v-if="campaignId && $store.getters['app/isMobile'] && isGeneralCampaign && template.landing_page_button_name && template.landing_page_url" class="web-get-coupon-container sticky-coupon-footer sticky-campagin-button">
      <a class="landing-page" data-aid="offer_detail_get_coupon" :href="template.landing_page_url" @click="bookNowClick"><div :class="{'web-get-coupon-button':true, 'primary-button': true, 'get-type-19': true}">{{ template.landing_page_button_name }}</div></a>
    </div>

    <!-- do not remove loading img loading-dot.gif-->
    <div v-if="isGoodieBagCampagin" class="loading-dot" style="background-image:url(/img/loading-dot.gif);height:0px" />
    <!-- end -->

    <el-drawer
      class="goodie-bag-drawer"
      :visible.sync="goodieBagDrawer"
      :title="$t('goodiebagConfirmation')"
      :direction="$store.getters['app/isMobile']?'btt':'rtl'"
    >
      <div class="goodie-bag-drawer-body">
        <div v-if="scrollphotos && scrollphotos.length" class="goodie-bag-drawer-img" :style="{backgroundImage: 'url('+scrollphotos[0]+')'}" />
        <div class="body-title">
          <div class="title">{{ name }}</div>
          <div class="type">{{ brandName }}</div>
        </div>
      </div>
      <div class="goodie-bag-drawer-price">
        <div v-if="goodiebagCampaginExpiryDate" class="line">
          <div class="left" style="width:50%">{{ $t('goodiebagCampaginExpiryDateText') }}</div>
          <div class="right s-size">{{ goodiebagCampaginExpiryDate }}</div>
        </div>
        <hr v-if="goodiebagCampaginExpiryDate">
        <div class="line">
          <div class="left">{{ $t('goodiebagQuantily') }}</div>
          <div class="right">{{ requireAmount }}</div>
        </div>
        <div v-if="!isUnlimitedReward && !isGoodieBagGift" class="line">
          <div class="left">{{ isGoodieBagGift?$t('goodiebagTotalQuotas'):$t('goodiebagTotalCreditsrequired') }}</div>
          <div class="right price" :class="{'gift': isGoodieBagGift}">-{{ goodieBagCost*requireAmount }}</div>
        </div>
        <hr v-if="!isUnlimitedReward">
        <div v-if="!isUnlimitedReward" class="line">
          <div class="left">{{ isGoodieBagGift?$t('goodiebagRemainingQuotas'):$t('goodiebagRemainingCredits') }}</div>
          <div class="right s-size">{{ goodieCreditsOrQuota - goodieBagCost*requireAmount }}</div>
        </div>
      </div>
      <div class="goodie-bag-drawer-bottom" data-aid="offer_detail_get_coupon_confirm">
        <div v-if="isAcquiring" class="button loading">
          <div class="loading-dot" style="background-image:url(/img/loading-dot.gif)" />
        </div>
        <div v-else class="button primary-button" @click="payGoodieBag">{{ $t('goodiebagConfirm') }}
          <img
            class="white-arrow-right"
            srcset="/img/white-arrow-right.1x.png, /img/white-arrow-right.2x.png 2x, /img/white-arrow-right.3x.png 3x"
            src="/img/white-arrow-right.2x.png"
          ></div>
      </div>
      <div class="goodie-bag-reminder">{{ goodiebagReminder }}</div>
    </el-drawer>

    <el-dialog
      title=""
      :visible.sync="isShowBarCodeDialog"
      :class="{'wide-code': isWideCode}"
      width="316px"
      center
      custom-class="coupon-alert-bar-dialog"
      :lock-scroll="true"
    >
      <div class="scan-code-success-dialog-content">
        <div class="scan-code-success-bar-code" :class="{'qr-code': formats==='COUPON_QR_CODE'}">
          <canvas v-show="formats==='COUPON_QR_CODE'" id="barcode-qr-canvas" />
          <barcode v-if="formats!=='COUPON_QR_CODE'" :value="barcodeValue" :options="barcodeOption" />
          <div v-if="formats!=='COUPON_QR_CODE' && isWideCode" class="rotation-reminder">
            <img src="@/assets/images/rotation-hint.png">
            <p>{{ $t('mycouponfullWindowBarcode') }}</p>
          </div>
          <div v-if="displayCouponCode" class="partner-qr-bar-code-code">{{ barcodeValue }}</div>

        </div>
        <div
          class="
            scan-code-success-bar-footer"
        >
          {{ $t('myWalletMerchantScan') }}
        </div>
      </div>
    </el-dialog>

    <!--
    <el-dialog
      title=""
      :visible.sync="isScanCodeSuccessDialog"
      width="343px"
      center
      custom-class="scan-code-success-dialog full-dialog"
      :lock-scroll="true"
    >
      <div class="scan-code-success-dialog-content">
        <div class="scan-code-success-check">&check;</div>
        <div class="scan-code-success-description">{{ $t('myWalletSuccessfullyRedeemedTitle') }}</div>
        <div class="scan-code-success-message">{{ redeemedTime }}</div>
        <div class="scan-code-success-message">{{ storeInfo.name }} <div v-if="storeInfo.district">({{ storeInfo.district.name }})</div></div>
        <div v-if="$t('myWalletSuccessfullyRedeemedSuffix')" class="scan-code-success-description qrcode-successfully-redeemed-suffix">{{ $t('myWalletSuccessfullyRedeemedSuffix') }}</div>
      </div>
      <span slot="footer" class="dialog-footer scan-code-success-footer">
        <div v-if="canSubmitSurvey" class="scan-code-success-take-survey" data-aid="coupon_detail_prompt_take_survey" @click="takeSurvey">{{ $t('myWalletTakeSurvey') }}</div>
        <div class="scan-code-success-done-button" @click="isQrcodeSuccessDialog = false">{{ $t('myWalletSuccessfullyRedeemedDone') }}</div>
      </span>
    </el-dialog>
     -->

    <el-dialog
      title=""
      :visible.sync="showFullScreenBarCodeDialog"
      width="343px"
      center
      custom-class="barcode-full-screen full-dialog"
      :lock-scroll="true"
      :class="{'wide-code': isWideCode}"
    >
      <div class="barcode-full-screen-content">
        <barcode :value="barcodeValue" :options="barcodeOption" />
      </div>
    </el-dialog>

    <el-dialog
      title=""
      :visible.sync="isScanCodeSuccessDialog"
      width="343px"
      center
      custom-class="scan-code-success-dialog full-dialog"
      :lock-scroll="true"
      :class="{'wide-code': isWideCode}"
    >
      <div class="scan-code-success-dialog-content">
        <div v-if="isShowCouponIndicator" class="coupon-indicator" :style="{ backgroundColor: couponIndicatorColor }">{{ couponIndicatorText }}</div>
        <!--
        <img class="scan-code-success-img" src="/img/qrcode_success.png">
        -->
        <div class="scan-code-success-check">&check;</div>
        <div class="scan-code-success-name">{{ name }}</div>
        <div class="scan-code-success-description">{{ $t('myWalletSuccessfullyRedeemedTitle') }}</div>
        <div class="scan-code-success-message">{{ redeemedTime }}</div>
        <div class="scan-code-success-message scan-code-store">{{ storeInfo.name }} ({{ storeInfo.address }})</div>
        <div v-if="referenceId" class="reference-id-contain"><div style="margin-right: 3px">{{ $t('referenceID') }}</div><div>{{ referenceId }}</div></div>
        <div v-if="$t('myWalletSuccessfullyRedeemedSuffix')" class="scan-code-success-description qrcode-successfully-redeemed-suffix">{{ $t('myWalletSuccessfullyRedeemedSuffix') }}</div>
        <div class="coupon-content">
          <div class="coupon-type-container">
            <div class="coupon-type-tags">
              <div v-if="couponCampaignLabel" class="coupon-type starred">{{ couponCampaignLabel }}</div>
              <div v-if="!isCampaign" class="coupon-type" :class="{'limited-offer':isLimitedOffer,'voucher':isVoucher,'ticket':isTicket}">
                {{ isLimitedOffer?$t('goodiebagLimitedOffers'):isVoucher?$t('goodiebagCashVouchers'): isTicket?$t('myWalletTickets'): $t('myWalletCoupons') }}
              </div>
            </div>
          </div>
        </div>

        <div v-if="barcodeValue" class="scan-code-success-bar-code" :class="{'qr-code': formats==='COUPON_QR_CODE'}">
          <canvas v-show="formats==='COUPON_QR_CODE'" id="barcode-qr-canvas" />
          <barcode v-if="formats!=='COUPON_QR_CODE'" :value="barcodeValue" :options="barcodeOption" />
          <div v-if="formats!=='COUPON_QR_CODE' && isWideCode" class="rotation-reminder">
            <img src="@/assets/images/rotation-hint.png">
            <p>{{ $t('mycouponfullWindowBarcode') }}</p>
          </div>
          <div v-if="displayCouponCode" class="partner-qr-bar-code-code">{{ barcodeValue }}</div>
        </div>
        <div v-if="barcodeValue" class="scan-code-success-bar-footer">
          {{ $t('myWalletMerchantScan') }}
        </div>

        <div :class="{'scan-code-success-buttons':true, 'has-survey':canSubmitSurvey}">
          <div v-if="canSubmitSurvey" class="scan-code-success-take-survey" data-aid="coupon_detail_prompt_take_survey" @click="takeSurvey">{{ $t('myWalletTakeSurvey') }}</div>
          <div class="scan-code-success-done-button" @click="isScanCodeSuccessDialog = false">{{ $t('myWalletSuccessfullyRedeemedDone') }}</div>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      title=""
      :visible.sync="isShowApplicableStores"
      width="675px"

      class="applicable-stores-dialog"
      :lock-scroll="true"
      :before-close="beforeApplicableStoresClose()"
    >
      <div v-if="applicableStoresPageType=='detail'" slot="title">
        <img
          srcset="/img/app_store_back.1x.png, /img/app_store_back.2x.png 2x, /img/app_store_back.3x.png 3x"
          src="/img/app_store_back.2x.png"
          class="back-img"
          @click="pageTypeChange('list')"
        >
      </div>
      <div v-else-if="applicableStoresPageType=='list'" slot="title">
        <div class="applicable-stores-dialog-title">{{ isTicket?$t('campaignEventLocation'): $t('myWalletApplicableStores') }}</div>
      </div>
      <applicable-store :applicablestoreslist="applicableStoresList" :pagetype="applicableStoresPageType" @pagetypechange="pageTypeChange" />
    </el-dialog>

    <el-dialog
      :visible.sync="isShowMobileQrCodeAlert"
      width="452px"
      class="mobile-qrcode-dialog"
      center
      :show-close="false"
    >
      <div class="alert-mobile-qrcode-image">
        <div
          class="alert-mobile-back-icon"
          :style="{backgroundImage: `url(${require('@/assets/images/alert-mobile-back-icon.png')})`}"
          @click="isShowMobileQrCodeAlert=false"
        />
        <div class="alert-mobile-qrcode">
          <canvas id="alert-mobile-url-qr-code" />
        </div>
        <div class="alert-mobile-qrcode-content">
          {{ isTicket? $t('myWalletScanQRcodeTicketMessage'): $t('myWalletScanQRcodeMessage') }}
        </div>
      </div>
    </el-dialog>

    <el-dialog
      :width="$store.getters['app/isMobile'] ? '316px' : '600px'"
      top="20vh"
      :show-close="$store.getters['app/isMobile'] ? false : true"
      custom-class="coupon-use-confirm-dialog"
      :lock-scroll="true"
      :visible.sync="isShowCopyCoupon"
      :before-close="closeCopyCode"
    >
      <div slot="title" class="coupon-detail-use-title">{{ isTicket ? $t('myWalletUseTicketPromptTitle'): $t('myWalletUseCouponPromptTitle') }}</div>
      <div class="coupon-use-confirm-dialog-content">
        {{ isTicket ? $t('myWalletUseTicketPromptDescription'): $t('myWalletUseCouponPromptDescription') }}
      </div>
      <div class="coupon-detail-use-buttons">
        <div v-if="!$store.getters['app/isMobile']" class="coupon-detail-use-button coupon-detail-cancel-use-button" @click="cancelCopyCode">{{ $t('myWalletUseCouponPromptUselater') }}</div>
        <div v-else class="coupon-detail-use-button coupon-detail-cancel-use-button" @click="cancelCopyCode">{{ $t('myWalletUseCouponPromptUselater') }}</div>
        <div data-aid="coupon_detail_use_coupon_confirm" class="coupon-detail-use-button coupon-detail-cancel-confirm-button" @click="confirmCopyCode">{{ $t('myWalletUseCouponPromptConfirm') }}</div>
      </div>
    </el-dialog>

    <el-dialog
      title=""
      :visible.sync="isShowRecaptchaFail"
      class="recaptcha-fail"
      :width="$store.getters['app/isMobile']?'316px':'600px'"
      center
      custom-class="coupon-alert-bar-dialog"
      :lock-scroll="true"
      :show-close="$store.getters['app/isMobile']?false:true"
    >
      <div slot="title" class="goodie-bag-sold-out-title">{{ recaptchaFailTitle }}</div>
      <div class="goodie-bag-sold-out-content" v-html="recaptchaFailContent" />
      <div class="goodie-bag-sold-out-bottom">
        <div class="button primary-button" @click="closeRecaptchaError()">{{ $t('goodiebagOk') }}</div>
      </div>
    </el-dialog>

    <el-dialog
      title=""
      :visible.sync="isShowGoodieBagFail"
      class="goodie-bag-fail"
      :width="$store.getters['app/isMobile']?'316px':'600px'"
      center
      custom-class="coupon-alert-bar-dialog"
      :lock-scroll="true"
      :show-close="$store.getters['app/isMobile']?false:true"
    >
      <div slot="title" class="goodie-bag-sold-out-title">{{ $t(goodieBagFailTitle) }}</div>
      <div class="goodie-bag-sold-out-content">
        {{ goodieBagFailText }}
      </div>
      <div class="goodie-bag-sold-out-bottom">
        <div class="button primary-button" @click="closeGoodieBagError()">{{ $t('goodiebagOk') }}</div>
      </div>
    </el-dialog>

    <el-dialog
      title=""
      :visible.sync="isShowGoodieBagSuccess"
      width="316px"
      center
      custom-class="coupon-alert-bar-dialog"
      :lock-scroll="true"
      :show-close="false"
    >
      <acquires-credits-success :name="name" :count="requireAmount" :type="isGoodieBagVoucher?'CASH_VOUCHER':'GIFT'" :goodiecreditsorquota="goodieCreditsOrQuota" :coupondisplayin="couponDisplayIn" />
    </el-dialog>

    <el-dialog
      title=""
      :visible.sync="isShowQueueError"
      class="queue-error"
      :width="'316px'"
      center
      custom-class="coupon-alert-bar-dialog"
      :lock-scroll="true"
      :show-close="false"
    >
      <div slot="title" class="queue-error-title">{{ $t('sessionTitle') }}</div>
      <div class="queue-error-content">
        {{ $t('sessionContent') }}
      </div>
      <div class="queue-error-bottom">
        <div class="button primary-button" @click="closeQueueError()">{{ $t('goodiebagOk') }}</div>
      </div>
    </el-dialog>

    <el-dialog
      title=""
      :visible.sync="isShowAgeRestricted"
      class="age-declaration"
      :width="$store.getters['app/isMobile']?'316px':'600px'"
      center
      custom-class="coupon-alert-bar-dialog"
      :show-close="false"
      :lock-scroll="true"
      :close-on-click-modal="false"
    >
      <div slot="title" class="age-declaration-title" v-html="$t('ageTitle')" />
      <div class="age-declaration-content" v-html="$t('ageContent')" />
      <div class="age-declaration-bottom">
        <div class="button primary-button ok" @click="ageContentOkClick()">
          <div>{{ $t('ageContentOK') }}</div>
          <div class="second">{{ $t('ageContentOKSecond') }}</div>
        </div>
        <div class="button secondary-button cancel" @click="ageContentCancelClick()">
          <div>{{ $t('ageContentCancel') }}</div>
          <div class="second">{{ $t('ageContentCancelSecond') }}</div>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="showCampaignErrorDialog"
      class="fail-to-acquire-coupon"
      width="316px"
      :title="checkLinkErrorMessageDict && checkLinkErrorMessageDict.display_error_content"
      center
      :show-close="false"
    >
      <span slot="footer" class="dialog-footer">

        <el-button
          tag="button"
          type="button"
          class="el-button el-button--primary primary-button"
          @click="showCampaignErrorDialog = false"
        >
          {{ $t('goodiebagOk') }}
        </el-button>
      </span>
    </el-dialog>

    <el-dialog
      title=""
      :visible.sync="isReminder"
      class="coupon-campaign-reminder"
      center
      :show-close="false"
      width="316px"
      custom-class="coupon-alert-bar-dialog"
    >
      <div slot="title" class="coupon-campaign-reminder-title">{{ $t('couponCampaginReminderTitle') }}</div>
      <div class="coupon-campaign-reminder-content ">{{ $t('couponCampaginReminderContent') }}</div>
      <div class="coupon-campaign-reminder-bottom">
        <div class="button primary-button ok" @click="reminderOkClick()">{{ $t('couponCampaginReminderConfirm') }}</div>
        <div class="button secondary-button" @click="reminderCancelClick()">{{ $t('couponCampaginReminderCancel') }}</div>
      </div>

    </el-dialog>

    <div v-show="showCodeType==='qr-code'" id="scan-qr-code">
      <div class="qr-code-full-window">
        <div class="qr-contain full-width">
          <div class="full-width close-contain">
            <img class="close" src="/img/scan_close.png" width="40px" height="40px" @click="close()">
          </div>
          <div class="title-contain">
            {{ $t('myWalletScanQRcodeScanning') }}
          </div>
          <div class="title-contain">
            {{ $t('myWalletScanQRcode') }}
          </div>
          <div class="description-contain">
            {{ $t('myWalletScanQRcodeAlign') }}
          </div>
        </div>
        <div id="qr-code-full-region" />
      </div>
      <el-dialog
        title=""
        :visible.sync="isQrcodeErrorDialog"
        width="316px"
        top="33vh"
        center
        @close="closeInvalidDialog"
      >
        <div class="scan-code-success-dialog-content">
          <div class="qrcode-error-message">{{ $t('myWalletInvalidQRcodeLineOne') }}</div>
          <div class="qrcode-error-message">{{ $t('myWalletInvalidQRcodeLineTwo') }}</div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" class="scan-code-success-done" @click="closeInvalidDialog">{{ $t('myAccountDeleteAccountconfirm2PromptOK') }}</el-button>
        </span>
      </el-dialog>
      <div
        v-loading.fullscreen.lock="isLoading"
        element-loading-text=""
        element-loading-spinner="get-coupe-icon-loading"
        element-loading-background="#F7F7F7"
      />
    </div>

    <el-dialog
      title=""
      :visible.sync="showFullPartnerBarCodeDialogDesktop"
      :class="{'wide-code': isWideCode}"
      width="auto"
      custom-class="desktop-coupon-alert-bar-dialog"
      :lock-scroll="true"
    >
      <div class="view-barcode-text">{{ $t('ViewFullBarcode') }}</div>
      <div class="full-barcode-content">
        <barcode :value="barcodeValue" :options="barcodeOption" />
      </div>
    </el-dialog>

    <div id="robot" />
  </div>
</template>
<script>
import VueBarcode from '@chenfengyuan/vue-barcode'

import { VueAgile } from 'vue-agile'
import ApplicableStore from '@/components/ApplicableStore'
import AcquiresCreditsSuccess from '@/components/AcquiresCreditsSuccess'
import GoodieBagBanner from '@/components/GoodieBagBanner'
import TourCard from '@/components/TourCard'
import utilsMixin from '@/mixins/utilsMixin'
import backHandlerMixin from '@/mixins/backHandlerMixin'
import axios from 'axios'
// import { getSsoAuthClient } from '@/helpers/http'
import { isUnavailable, isRedeemedOrExpired, isAvailable, isRedeemed, isExpired } from '@/utils/coupon'
// import config from '@/config'
import { getLangName } from '@/utils/lang'
import { sendPageViewAnalytics, sendManualAnalytics, sendAdobeManualAnalytics } from '@/utils/tracking'
import { asyncGetCampaignDetail, loginSSO, getCampaignlimit } from '@/helpers/http'
import { isDev, getChannelName, isWechatMiniProgram,
  getGoodieBagCampaignSlot, getCampaignAPIPrefix,
  pushRouterWithQuery, replaceRouterWithQuery } from '@/utils/utils'
import NotFind from '@/components/NotFind'
import Cookies from 'js-cookie'
import { setupDefaultQueryString } from '../utils/utils'

// import { testData } from '@/utils/testData'

const BIG_NUMBER = 999999

export default {
  name: 'CouponDetail',
  components: {
    agile: VueAgile,
    ApplicableStore,
    TourCard,
    barcode: VueBarcode,
    AcquiresCreditsSuccess,
    GoodieBagBanner,
    NotFind
  },
  mixins: [utilsMixin, backHandlerMixin],
  data () {
    return {
      showCodeType: 'coupon-detail',
      html5Qrcode: '',
      isShowMobileQrCodeAlert: false,
      campaignId: '',
      couponId: '',
      recordId: '',
      hasGotCoupon: false,
      lockScan: false,
      isShowQrCanvas: false,
      isScanCodeSuccessDialog: false, // walty temp
      isQrcodeErrorDialog: false,
      isShowCouponIndicator: false,
      couponIndicatorColor: '',
      couponIndicatorText: '',
      isShowApplicableStores: false,
      applicableStoresPageType: 'list',
      isShowCopyCoupon: false,
      isLoading: false,
      isShowBarCodeDialog: false,
      isShowBrandDescription: false,
      redeemedTime: null,
      isShowTourPreButton: false,
      isShowTourNextButton: true,
      storeInfo: {},
      campaignDetailCollected: false,
      isUpdated: false,
      tourCardIsFirstUpdated: false,
      hideRedeemeButton: false,
      requireAmount: 1,
      goodieBagDrawer: false,
      isShowGoodieBagFail: false,
      goodieBagFailTitle: 'goodiebagFailedAcquire1',
      goodieBagFailText: '',
      isShowGoodieBagSuccess: false,
      isAcquiring: false,
      displayIn: null,
      checkLinkErrorMessageDict: '',
      isShowQueueError: false,
      isNotCancelAgeRestricted: false,
      showCampaignErrorDialog: false,
      slotId: '',
      isReminder: false,
      isOpeningCamera: false,
      couponNotFound: false,
      qrCodeWidth: '270',
      showFullPartnerBarCodeDialogDesktop: false,
      useCouponFailToMyWallet: false,
      isInCheckUseCoupon: false,
      isShowRecaptchaFail: false,
      recaptchaFailTitle: '',
      recaptchaFailContent: '',
      isVerifyRecaptcha: true,
      needTrackCampaignDetail: false
    }
  },
  computed: {
    showFullScreenBarCodeDialog () {
      return this.$store.getters['app/isHorizontal'] && this.isWideCode && (this.isScanCodeSuccessDialog || this.isShowBarCodeDialog || (this.$store.getters['app/isMobile'] && this.isShowPartnerBarCode))
    },
    goodieDownloadCount () {
      // console.log('@2385a', this.template.coupon_count.download_count)

      if (!this.template || !this.template.coupon_count) { return 0 }

      return this.template.coupon_count.download_count
    },
    goodieOwnedCount () {
      // console.log('@2385b', this.template.coupon_count.owned_count)

      if (!this.template || !this.template.coupon_count) { return 0 }

      return this.template.coupon_count.owned_count
    },
    isNoData () {
      let result = false
      if (
        (
          this.couponId && this.$store.getters['coupons/couponList']().length &&
            (!this.$store.getters['coupons/couponDetail'](this.couponId) ||
            this.$store.getters['coupons/couponDetail'](this.couponId) &&
            this.$store.getters['coupons/couponDetail'](this.couponId).template &&
            !this.$store.getters['coupons/couponDetail'](this.couponId).template.id)
        ) || (
          this.campaignId && this.campaignDetailCollected &&
          (!this.$store.getters['campaigns/campaignDetail'](this.campaignId) ||
          !this.$store.getters['campaigns/campaignDetail'](this.campaignId).id)
        )
      ) {
        result = true
      }
      return result
    },
    offerTypeLabel () {
      let label = null
      if (this.campaignId) {
        label = this.campaignData.offer_type_label
      }
      return label
    },
    isShowTakeSurveyButton () {
      return this.couponTemplateType === 'IMPORT_EXISTING_COUPON'
    },
    specialCouponUserInfo () {
      console.log('this couponData', this.couponData)
      if (this.couponData && this.couponData.owner_profile && this.couponData.owner_profile.identity_number) {
        const fullName = this.couponData.owner_profile.fullname
        const identityNumber = this.couponData.owner_profile.identity_number
        return `${fullName} (${identityNumber}****)`
      }
      return null
    },
    isShowAgeRestricted () {
      if (this.isBackNavigation) { // do not show for back navigation
        return false
      }

      let isShowAgeRestricted = false
      const { qty } = this.$route.query
      if (this.isAgeRestricted && !this.isNotCancelAgeRestricted && !qty) {
        isShowAgeRestricted = true
        this.disableOverflow()
      }
      return isShowAgeRestricted
    },
    showAvalibleTip () {
      let reuslt = null

      if (this.couponId) {
        const effectiveDate = this.couponData.effective_date

        if (effectiveDate && new Date() < new Date(effectiveDate)) {
          reuslt = this.getDateString(effectiveDate, 'D MMM YYYY')
        }
      }

      console.log('233------ showAvalibleTip', reuslt)
      return reuslt
    },
    isExpired () {
      return isExpired(this.couponData)
    },
    isInUsedTime () {
      console.log('1125', this.couponData.within_used_time)
      return this.couponData.within_used_time
    },
    isUnavailableButtonPadding () {
      if (this.$i18n.locale === 'en') {
        return '4px 8px'
      }
      return '4px 55px'
    },
    isCampaignExpired () {
      return this.campaignId && this.template && this.template.is_expired
    },
    isCampaignAvailable () {
      const profile = this.$store.getters['auth/userProfile']

      return !this.isCampaignExpired && !profile.is_preview_tester
    },
    isStarred () {
      let isStarred = false
      if (this.campaignData) {
        isStarred = this.campaignData.is_starred
      }
      if (this.couponData && this.couponData.campaign) {
        isStarred = this.couponData.campaign.is_starred
      }
      return isStarred
    },
    couponCampaignLabel () {
      if (this.couponData && this.couponData.goodie_bag && this.couponData.goodie_bag.coupon_label) {
        return this.couponData.goodie_bag.coupon_label.name
      }

      if (this.isStarred) {
        return this.$t('campaignSTARRED')
      }

      return null
    },
    eventName () {
      let eventName = ''
      if (this.campaignData && this.campaignData.is_event) {
        eventName = this.campaignData && this.campaignData.event_name || ''
      }
      return eventName
    },
    isRedeemed () {
      const result = isRedeemed(this.couponData)
      console.log('result isRedeemed', result)
      return result
    },
    canSubmitSurvey_ () {
      return true// walty temp
    },
    canSubmitSurvey () {
      let result = false
      const query = this.$route.query
      console.log('543 query: ', query)
      if (query.source && query.source === 'email') {
        result = false
        return result
      }
      if (this.couponId) {
        if (this.couponData && this.couponData.coupon_used_transaction && this.couponData.coupon_used_transaction.transaction_amount_survey_value) {
          result = false
        } else {
          result = this.template && this.template.require_transaction_amount_survey && this.isRedeemed
        }
      }
      console.log('63----- submit', result)
      return result
    },
    isCouponRedeemOrExpired () {
      console.log('this coupon data:', this.couponData, isRedeemedOrExpired(this.couponData), isUnavailable(this.couponData))
      return isRedeemedOrExpired(this.couponData)
    },
    isUnavailable () {
      return isUnavailable(this.couponData)
    },
    offerDetailTitle () {
      const title = ''
      const result = this.template ? this.template.offer_detail_title : title

      console.log('@8578', result)
      return result
    },
    offerDetailContent () {
      const content = ''
      console.log('this data: ', this.couponData)
      return this.template ? this.template.offer_detail_content : content
    },
    template () {
      let result = {}
      if (this.couponId && this.couponData && this.couponData.template) {
        result = this.couponData.template
        console.log('coupon data', this.couponData)
      } else {
        // result = this.campaignData.coupon_campaign_type_coupon_template
        result = this.campaignData.detail ? this.campaignData.detail : {}
      }
      console.log('item-----result: ', result)
      return result
    },
    applicableStoresList () {
      let stores = []
      if (this.campaignId) {
        if (this.campaignData.detail) {
          stores = this.formatApplicableStores(this.campaignData.detail.stores)
        }
      } else {
        stores = this.template ? this.formatApplicableStores(this.template.applicable_stores) : []
      }
      return stores
    },
    brandLogoUrl () {
      let url = '/img/trans1px.png'
      if (this.template && this.template.brand) {
        url = this.template.brand.icon_url
      }

      return url
    },
    logoLabels () {
      let result = []
      if (this.couponId) {
        console.log('268----- coupon item', this.template)
        result = this.template ? this.template.logo_labels : null
      } else {
        result = this.campaignData.logo_labels
      }
      return result
    },
    campaignStartDate () {
      let result = ''
      if (this.campaignId && this.campaignData && this.campaignData.start_date) {
        result = this.getDateString(this.campaignData.start_date, 'D MMM YYYY')
      }

      return result
    },
    goodiebagRecordDetail () {
      let goodiebagRecordDetail = null
      if (this.isGoodieBagCampagin) {
        goodiebagRecordDetail = this.$store.getters['goodiebagparticipationrecords/recordDetail'](this.recordId)
      }
      return goodiebagRecordDetail
    },
    validDate () {
      let result = ''
      if (this.isGoodieBagCampagin) {
        if (!this.goodiebagRecordDetail || !this.campaignData) {
          return result
        }
        const {
          coupon_acquire_end_date: recordEndDate
        } = this.goodiebagRecordDetail

        const {
          end_date: campaginEndDate,
          is_follow_goodie_bag_active_period: followGoodieBagActivePeriod
        } = this.campaignData
        result = this.getCampaignVaidTil(recordEndDate, campaginEndDate, followGoodieBagActivePeriod)
      } else if (this.campaignId) {
        console.log('253----- item', this.campaignData)
        if (this.campaignData.end_date) {
          result = this.$t('myWalletValidTime') + this.getLongDateString(this.campaignData.end_date)
        }
      } else {
        if (this.couponData.used_at) {
          const dateString = this.getDateString(this.couponData.used_at, 'DD MMM YYYY HH:mm:ss')
          result = this.$t('myWalletRedeemedTime', { dateString })
          if (this.usedStore) {
            result = result + ','
          }
        } else if (this.couponData.expiry_datetime) {
          result = this.$t('myWalletValidTime') + this.getLongDateString(this.couponData.expiry_datetime)
        } else {
          result = this.$t('myWalletNoExpiryDate')
        }
      }
      return result
    },
    usedStore () {
      let result = ''
      if (this.couponData.used_at) {
        if (this.couponData.coupon_used_transaction) {
          const store = this.couponData.coupon_used_transaction.store
          if (store && store.name && store.address) {
            result = result + `${store.name || ''} (${(store.district && store.district.name) || ''})`
          }
        }
      }
      return result
    },
    name () {
      // console.log('this campaign data', this.campaignData)
      if (this.campaignId) {
        return this.campaignData.name
      } else {
        return this.template ? this.template.name : ''
      }
    },
    shortDescription () {
      if (this.template) {
        return this.template.short_description
      }
      return ''
    },
    twitterShareContent () {
      const result = `${this.name} | ${this.brandName}\n${this.$t('campaignEnjoyOffer')}`
      return result
    },
    brandName () {
      let name = ''
      if (this.template && this.template.brand) {
        name = this.template.brand.name
      }
      return name
    },
    brandDescription () {
      let description = ''
      if (this.template && this.template.brand) {
        description = this.template.brand.description
      }
      return description
    },
    scrollphotos () {
      let photos = []
      if (this.campaignId) {
        photos = this.campaignData.photos_url
      } else {
        photos = this.template ? this.template.photos_url : []
      }
      console.log('280----- photos: ', photos)

      return photos
    },
    instructionTitle () {
      const title = ''
      return this.template ? this.template.instruction_section_title : title
    },
    instructionContent () {
      const content = ''
      return this.template ? this.template.instruction_section_content : content
    },
    detailSectionTitle () {
      let title = ''
      if (this.template) {
        title = this.template.detail_section_title
      }
      return title
    },
    detailSectionContent () {
      let content = ''
      if (this.template) {
        content = this.template.detail_section_content
      }
      return content
    },
    merchantTermsTitle () {
      let title = ''
      if (this.template) {
        if (this.template.merchant_terms_conditions_title) {
          title = this.template.merchant_terms_conditions_title
        } else {
          title = this.template.merchant_terms_and_conditions_title
        }
      }
      return title
    },
    campaignType () {
      let type = ''

      if (this.campaignId && this.campaignData) {
        type = this.campaignData.type
      } else if (this.template) {
        type = this.template.type
      }

      // console.log('@1039', type, this.type)
      return type
    },
    merchantTermsContent () {
      let content = ''
      if (this.template) {
        if (this.template.merchant_terms_conditions_content) {
          content = this.template.merchant_terms_conditions_content
        } else {
          content = this.template.merchant_terms_and_conditions_content
        }
      }
      return content
    },
    generalTermsTitle () {
      let title = ''
      if (this.template) {
        if (this.template.generic_terms_conditions_title) {
          title = this.template.generic_terms_conditions_title
        } else {
          title = this.template.generic_terms_and_conditions_title
        }
      }
      return title
    },
    generalTermsContent () {
      let content = ''
      if (this.template) {
        if (this.template.generic_terms_conditions_content) {
          content = this.template.generic_terms_conditions_content
        } else {
          content = this.template.generic_terms_and_conditions_content
        }
      }
      return content
    },
    isAgeRestricted () {
      let isAgeRestricted = false
      if (this.campaignData) {
        isAgeRestricted = this.campaignData.is_age_restricted
      }
      return isAgeRestricted
    },
    showStatementWordingForCountryCodeLimit () {
      let showStatementWordingForCountryCodeLimit = false
      if (this.campaignData) {
        showStatementWordingForCountryCodeLimit = this.campaignData.show_statement_wording_for_country_code_limit
      }
      return showStatementWordingForCountryCodeLimit
    },
    statementWordingForCountryCodeLimit () {
      let statementWordingForCountryCodeLimit = ''
      if (this.campaignData) {
        statementWordingForCountryCodeLimit = this.campaignData.statement_wording_for_country_code_limit
      }
      return statementWordingForCountryCodeLimit
    },
    campaignData () {
      let result = null
      if (this.campaignId && this.$route.name === 'campaign-detail') {
        result = this.$store.getters['campaigns/campaignDetail'](this.campaignId)
      }
      if (!result) {
        result = {}
      }

      // console.log('303---- result--- campain', result)
      return result
    },
    tourCardTitle () {
      let result
      if (this.template.is_follow_coupon_template_tour_card && this.template.coupon_campaign_type_coupon_template && this.template.coupon_campaign_type_coupon_template.tour_card_section_title) {
        result = this.template.coupon_campaign_type_coupon_template.tour_card_section_title
      } else {
        result = this.template.tour_card_section_title
      }
      return result
    },
    tourInfo () {
      if (!this.template) {
        return null
      }
      if (!this.template.show_tour_card) {
        return null
      }

      let result
      if (this.template.is_follow_coupon_template_tour_card && this.template.coupon_campaign_type_coupon_template && this.template.coupon_campaign_type_coupon_template.tour_cards) {
        result = this.template.coupon_campaign_type_coupon_template.tour_cards
      } else {
        result = this.template.tour_cards
      }

      result = result || []

      result = result.filter(item => item.tour_card && item.tour_card.id) // filter out empty object
      // console.log('@808, tour cards: ', JSON.stringify(result))
      return result
    },

    couponData () {
      let result = null
      if (this.couponId) {
        result = this.$store.getters['coupons/couponDetail'](this.couponId)
        // console.log('616------result: ', result)
      }
      if (!result) {
        result = {}
      }
      // console.log('303---- result--- coupons', result)
      return result
    },
    displayCouponCode () {
      if (this.couponData && this.couponData.template) {
        return this.couponData.template.display_code
      }
      return false
    },
    barcodeValue () {
      let code = '' // walty temp
      if (this.couponData.coupon_used_transaction) {
        code = this.couponData.coupon_used_transaction.code
      }

      console.log('@939', code)
      return code
    },
    barcodeFormat () {
      let format = 'auto'
      if (this.couponData && this.couponData.template && this.couponData.template.barcode_format) {
        format = this.couponData.template.barcode_format
      }

      format = format.toLowerCase()
      format = format.replace(/ /, '')
      format = format.replace('upc-a', 'upc')
      format = format.replace('-', '')

      console.log('@935', format)
      return format
    },
    couponCode () {
      if (this.couponData &&
          this.couponData.coupon_used_transaction && this.couponData.coupon_used_transaction.code) {
        return this.couponData.coupon_used_transaction.code
      }

      return 'N/A'
    },
    couponCodeUrl () {
      if (this.couponData && this.couponData.template &&
          this.couponData.template.landing_page_url) {
        return this.couponData.template.landing_page_url
      }
      return ''
    },
    couponShopNowName () {
      if (this.couponData && this.couponData.template &&
          this.couponData.template.landing_page_button_name) {
        return this.couponData.template.landing_page_button_name
      }
      return ''
    },
    couponCodeFormat () {
      if (this.couponData && this.couponData.template &&
          this.couponData.template.discount_code_format) {
        return this.couponData.template.discount_code_format
      }
      return ''
    },
    qrCodeCouponCode () {
      return this.couponData.serial_number
    },
    couponRedeemMethod () {
      let result = this.template ? this.template.redemption_method : null

      console.log('@1473', result, this.couponData, this.couponData.redemption_method)
      if (!result && this.couponData && this.couponData.redemption_method) {
        result = this.couponData.redemption_method
      }

      return result
    },
    couponTemplateType () {
      return this.template ? this.template.coupon_template_type : null
    },
    isCampainActive () {
      if (this.campaignData) {
        console.log('427---- campaign data: ', this.campaignData.is_active)
        return this.campaignData.is_active && this.campaignData.is_visible
      }
      return false
    },
    canShowViewBarCode () {
      return this.couponRedeemMethod === 'USERS_SCAN_STORE_QR_CODE' && this.couponTemplateType === 'IMPORT_EXISTING_COUPON'
    },
    canShowCopyCodeButton () {
      return this.couponRedeemMethod === 'USERS_COPY_THE_DISCOUNT_CODE' && (isRedeemed(this.couponData))
    },
    canShowUseCouponButton () {
      return this.couponRedeemMethod === 'USERS_COPY_THE_DISCOUNT_CODE' && isAvailable(this.couponData) && this.couponData.within_used_time !== undefined
    },
    isShowCopyCodeInfo () {
      return this.couponRedeemMethod === 'USERS_COPY_THE_DISCOUNT_CODE' && isRedeemed(this.couponData)
    },
    isShowPartnerQRCode () {
      return this.couponRedeemMethod === 'USERS_COPY_THE_DISCOUNT_CODE' && this.couponCodeFormat === 'PARTNER_QR_CODE' && isRedeemed(this.couponData)
    },
    isShowPartnerBarCode () {
      return this.couponRedeemMethod === 'USERS_COPY_THE_DISCOUNT_CODE' && this.couponCodeFormat === 'PARTNER_BARCODE' && isRedeemed(this.couponData)
    },
    isPartnerCode () {
      return this.isShowPartnerQRCode || this.isShowPartnerBarCode
    },
    isPartnerCodeCoupon () {
      return this.couponRedeemMethod === 'USERS_COPY_THE_DISCOUNT_CODE' && (this.couponCodeFormat === 'PARTNER_QR_CODE' || this.couponCodeFormat === 'PARTNER_BARCODE')
    },
    shareUrl () {
      const { lang } = this.$route.params
      const langName = getLangName(lang).toLowerCase()
      const result = window.location.origin + '/share-campaigns/' + this.campaignId + '?lang=' + langName
      console.log('@1162', result)
      return result
    },
    isGeneralCampaign () {
      const result = !!this.campaignType && this.campaignType === 'GENERAL_MESSAGE_CAMPAIGN'
      console.log('@856, is general campaign', result)
      return result
    },
    isGoodieBagGift () {
      const result = !!this.campaignType && this.campaignType === 'GIFT_CAMPAIGN_FOR_GOODIE_BAG'
      return result
    },
    isGoodieBagVoucher () {
      const result = !!this.campaignType && this.campaignType === 'CASH_VOUCHER_CAMPAIGN_FOR_GOODIE_BAG'
      return result
    },
    isGoodieBagCampagin () {
      return this.campaignData && (this.isGoodieBagGift || this.isGoodieBagVoucher)
    },
    isCouponCampagin () {
      const result = this.campaignData && !!this.campaignType && this.campaignType === 'COUPON_CAMPAIGN'
      return result
    },
    isNeedShowLimitText () {
      return this.campaignId && this.campaignData && this.campaignData.type && !this.isGeneralCampaign &&
        (this.goodiePerHeadLimit || this.goodiePerHeadOwnLimit)
    },
    campaignCanGetCoupon () {
      return this.isCampainActive && !this.hasGotCoupon
    },
    campaignGetCouponText () {
      /*
                  <div v-if="isCampainActive" class="web-get-coupon-button" :class="{'coupon-unavailable': !isCampainActive || hasGotCoupon}" @click="getCoupon">{{ $t('campaignGetCoupon') }}</div>
            <div v-else class="web-get-coupon-button coupon-unavailable">Availble on {{ campaignStartDate }}</div>
            */
      if (this.isCampainActive) {
        return this.$t('campaignGetCoupon')
      }

      return this.$t('campaignAvailableOn') + ' ' + this.campaignStartDate
      // return this.$t('myWalletAvailableTime') + ' ' + this.campaignStartDate + ' ' + this.$t('myWalletAvailableTimeSuffix')
    },
    goodieBagCost () {
      let goodieBagCost = null
      if (this.campaignData) {
        if (this.isGoodieBagGift) {
          goodieBagCost = 1
        } else {
          goodieBagCost = this.campaignData.goodie_bag_reward
        }
      }
      if (this.isUnlimitedReward) {
        goodieBagCost = null
      }

      console.log('@1538c', goodieBagCost)
      return goodieBagCost
    },
    goodiePerHeadLimit () {
      let goodiePerHeadLimit = 0
      if (this.campaignData) {
        goodiePerHeadLimit = this.campaignData.coupon_campaign_type_per_head_limit
      }
      return goodiePerHeadLimit
    },
    goodiePerHeadDownloadLimit () {
      let goodiePerHeadDownloadLimit = 0
      if (this.campaignData) {
        goodiePerHeadDownloadLimit = this.campaignData.coupon_campaign_type_per_head_download_limit
      }
      return goodiePerHeadDownloadLimit
    },
    goodiePerHeadDownloadLimitEachTime () {
      let goodiePerHeadDownloadLimitEachTime = 1
      if (this.campaignData) {
        goodiePerHeadDownloadLimitEachTime = this.campaignData.coupon_campaign_type_per_head_download_for_each_time
      }

      return goodiePerHeadDownloadLimitEachTime
    },
    goodiePerHeadOwnLimit () {
      let goodiePerHeadOwnLimit = 0
      if (this.campaignData) {
        goodiePerHeadOwnLimit = this.campaignData.coupon_campaign_type_per_head_owned_limit
      }
      return goodiePerHeadOwnLimit
    },
    goodiePerHeadOwnStarredLimit () {
      let goodiePerHeadOwnStarredLimit = 0
      const campaignLimits = this.$store.getters['campaigns/getCampaignLimits']
      if (campaignLimits) {
        goodiePerHeadOwnStarredLimit = campaignLimits.star_campaign_per_head_owned_limit
      }
      return goodiePerHeadOwnStarredLimit
    },
    goodieDownloadQuota () {
      const goodiePerHeadLimit = this.goodiePerHeadLimit === null ? BIG_NUMBER : this.goodiePerHeadLimit
      const goodiePerHeadDownloadLimit = this.goodiePerHeadDownloadLimit === null ? BIG_NUMBER : this.goodiePerHeadDownloadLimit
      const result = Math.min(goodiePerHeadLimit - this.goodieDownloadCount, goodiePerHeadDownloadLimit)
      return result
    },
    customerOwnedStarCampaignCouponQuantity () {
      let customerOwnedStarCampaignCouponQuantity = null
      const campaignLimits = this.$store.getters['campaigns/getCampaignLimits']
      if (campaignLimits) {
        customerOwnedStarCampaignCouponQuantity = campaignLimits.customer_owned_star_campaign_coupon_quantity
      }
      return customerOwnedStarCampaignCouponQuantity
    },
    couponStock () {
      let couponStock = null
      if (this.campaignData) {
        couponStock = this.campaignData.coupon_campaign_type_coupon_stock
      }
      return couponStock
    },
    couponDisplayIn () {
      let couponDisplayIn = ''
      if (this.campaignData && this.campaignData.coupon_campaign_type_coupon_template && this.campaignData.coupon_campaign_type_coupon_template.display_in) {
        couponDisplayIn = this.campaignData.coupon_campaign_type_coupon_template.display_in
      }
      return couponDisplayIn
    },
    goodieBagAvailableTime () {
      let goodieBagAvailableTime = null
      if (this.isGoodieBagCampagin && this.goodiebagRecordDetail) {
        const recordDetail = this.goodiebagRecordDetail
        let startDate

        const {
          coupon_acquire_start_date: recordStartDate
        } = this.goodiebagRecordDetail

        const {
          start_date: campaginStartDate,
          is_follow_goodie_bag_active_period: followGoodieBagActivePeriod
        } = this.campaignData

        if (followGoodieBagActivePeriod) {
          startDate = recordStartDate
        } else {
          if (recordStartDate && campaginStartDate) {
            startDate = new Date(recordStartDate) < new Date(campaginStartDate) ? campaginStartDate : recordStartDate
          } else {
            startDate = recordStartDate || campaginStartDate
          }
        }

        if (recordDetail && startDate && new Date() < new Date(startDate)) {
          goodieBagAvailableTime = this.getDateString(new Date(startDate), 'D MMM YYYY HH:mm')
        }

        console.log('@1538a', recordStartDate, campaginStartDate)
      }

      console.log('@1538', goodieBagAvailableTime)
      return goodieBagAvailableTime
    },
    campaignSlot () {
      return getGoodieBagCampaignSlot(this.goodiebagRecordDetail, this.slotId)
    },
    isUnlimitedReward () {
      if (this.isGoodieBagCampagin && this.campaignSlot && this.isGoodieBagGift) {
        if (this.campaignSlot.assign_reward_per_link === null) {
          return true
        }
      }
      return false
    },
    goodieCreditsOrQuota () {
      let goodieCreditsOrQuota = 0
      if (this.isGoodieBagCampagin) {
        const recordDetail = this.goodiebagRecordDetail
        if (this.campaignSlot) {
          goodieCreditsOrQuota = this.campaignSlot.owned_reward
        } else {
          if (recordDetail && this.isGoodieBagVoucher) {
            goodieCreditsOrQuota = recordDetail['owned_credit']
          }
          if (recordDetail && this.isGoodieBagGift) {
            goodieCreditsOrQuota = recordDetail['owned_quota']
          }
        }
        if (this.isUnlimitedReward) {
          goodieCreditsOrQuota = 'un'
        }
      }

      console.log('@1538d', goodieCreditsOrQuota)
      return goodieCreditsOrQuota
    },
    isShowLowStockLabel () {
      let isShowLowStockLabel = false
      if (this.campaignData) {
        isShowLowStockLabel = this.campaignData.show_low_stock_label
      }
      return isShowLowStockLabel
    },
    isShowOutOfStockLabel () {
      let isShowOutOfStockLabel = false
      if (this.campaignData) {
        isShowOutOfStockLabel = this.campaignData.show_out_of_stock_label
      }
      return isShowOutOfStockLabel
    },
    getCouponStockMessage () {
      let getCouponStockMessage = ''
      if (this.checkLinkErrorMessageDict &&
          this.checkLinkErrorMessageDict.detail === 'This offer is not applicable for the country of your registered number.'
      ) {
        getCouponStockMessage = this.$t('campaginInvalidCountryCode')
      } else if (this.isStarred && this.customerOwnedStarCampaignCouponQuantity && this.customerOwnedStarCampaignCouponQuantity >= this.goodiePerHeadOwnStarredLimit) {
        getCouponStockMessage = this.$t('campaignExceededTheMax', { limit: this.goodiePerHeadOwnStarredLimit })
      } else if (this.checkLinkErrorMessageDict &&
          this.checkLinkErrorMessageDict.display_error_content) {
        getCouponStockMessage = this.checkLinkErrorMessageDict.display_error_content
      }
      // else if (this.goodiePerHeadLimit === this.goodiePerHeadOwnLimit) {
      //  getCouponStockMessage = 'You have exceeded the max. number (4) of coupon you can acquire (for this coupon). You could try on other campaigns.'
      // } else if (this.goodiePerHeadLimit > this.goodiePerHeadOwnLimit) {
      //  getCouponStockMessage = 'You have exceeded the max. number (2) of coupon you can own (for this coupon). You may use some of them before acquiring more.'
      // }
      return getCouponStockMessage
    },
    referenceId () {
      let referenceId = '' // walty temp
      if (this.couponData && this.couponData.reference_id) {
        referenceId = this.couponData.reference_id
      }
      return referenceId
    },
    formats () {
      let formats = null
      if (this.couponData && this.couponData.template && this.couponData.template.formats) {
        formats = this.couponData.template.formats
      }
      return formats
    },
    barcodeOption () {
      const barcodeOption = { format: this.barcodeFormat }
      if (this.isWideCode) {
        barcodeOption['width'] = 1
      }
      return barcodeOption
    },
    isWideCode () {
      return this.barcodeFormat === 'code39' || this.barcodeFormat === 'code128'
    },
    // goodieBagEndTime () {
    //  let goodieBagEndTime = ''
    //  if (this.isGoodieBagCampagin) {
    //    const recordDetail = this.goodiebagRecordDetail
    //    if (recordDetail && recordDetail['coupon_acquire_end_date']) {
    //      goodieBagEndTime = this.getDateString(new Date(recordDetail['coupon_acquire_end_date']), 'D MMM YYYY, HH:mm')
    //    }
    //  }
    //  return goodieBagEndTime
    // },
    // goodieBagEndData () {
    //  let goodieBagEndData = ''
    //  if (this.isGoodieBagCampagin) {
    //    const recordDetail = this.goodiebagRecordDetail
    //    if (recordDetail && recordDetail['coupon_acquire_end_date']) {
    //      goodieBagEndData = this.getDateString(new Date(recordDetail['coupon_acquire_end_date']), 'D MMM YYYY')
    //    }
    //  }
    //  return goodieBagEndData
    // }
    hasDisabledGoodibagAcquire () {
      const goodiePerHeadOwnLimit = this.goodiePerHeadOwnLimit === null ? BIG_NUMBER : this.goodiePerHeadOwnLimit
      const downloadLimitReached = this.goodieDownloadCount &&
      (
        this.goodieDownloadQuota <= 0 ||
       this.goodieOwnedCount >= goodiePerHeadOwnLimit
      )

      console.log('@1697', this.goodieCreditsOrQuota, this.goodieBagCost, this.getCouponStockMessage, downloadLimitReached, this.goodieDownloadQuota, this.goodieOwnedCount, goodiePerHeadOwnLimit)
      return (!this.isUnlimitedReward && this.goodieCreditsOrQuota < this.goodieBagCost) || this.getCouponStockMessage || downloadLimitReached
    },
    goodiebagCampaginExpiryDate () {
      let expiryDate = ''
      const couponTemplate = this.campaignData.coupon_campaign_type_coupon_template

      if (couponTemplate) {
        if (couponTemplate.valid_period_type === 'ABSOLUTE') {
          expiryDate = couponTemplate.absolute_expiry_datetime
        } else if (couponTemplate.valid_period_type === 'RELATIVE') {
          const now = new Date()
          let expiryDateTime = this.$moment(now).add(couponTemplate.number_of_days_to_expire_after_acquisition,
            'days').endOf('day')
          if (couponTemplate.relative_valid_set_deadline && expiryDateTime > this.$moment(couponTemplate.absolute_expiry_datetime)) {
            expiryDateTime = this.$moment(couponTemplate.absolute_expiry_datetime)
          }

          expiryDate = expiryDateTime.toDate()
        }
      }

      if (expiryDate) {
        expiryDate = this.getDateString(expiryDate, 'DD MMM YYYY HH:mm')
      }

      return expiryDate
    },
    goodiebagReminder () {
      let goodiebagReminder = this.$t('goodiebagReminderAllTime')
      const couponTemplate = this.campaignData.coupon_campaign_type_coupon_template
      if (couponTemplate) {
        if (couponTemplate.valid_period_type === 'RELATIVE') {
          const requiredDays = couponTemplate.number_of_days_to_expire_after_acquisition

          const now = new Date()
          const expiryDateTime = this.$moment(now).add(requiredDays, 'days').endOf('day')

          if (couponTemplate.relative_valid_set_deadline && expiryDateTime > this.$moment(couponTemplate.absolute_expiry_datetime)) {
            const endDate = this.$moment(couponTemplate.absolute_expiry_date).format('DD-MM-YYYY')
            goodiebagReminder = this.$t('goodiebagReminderAbsolute', { endDate: endDate })
          } else {
            goodiebagReminder = this.$t('goodiebagReminderRelative', { day: requiredDays })
          }
        } else if (couponTemplate.valid_period_type === 'ABSOLUTE') {
          const endDate = this.$moment(couponTemplate.absolute_expiry_date).format('DD-MM-YYYY')
          console.log('@1784', this.$moment(couponTemplate.absolute_expiry_date).format('DD-MM-YYYY'))
          goodiebagReminder = this.$t('goodiebagReminderAbsolute', { endDate: endDate })
        }
      }

      return goodiebagReminder
    },
    isTicket () {
      return this.verifyDisplayIn('TICKET_SECTION')
    },
    isVoucher () {
      return this.verifyDisplayIn('VOUCHER_SECTION')
    },
    isLimitedOffer () {
      return this.verifyDisplayIn('LIMITED_OFFER_SECTION')
    },
    isMerchantAppScanCouponQrCode () {
      let isMerchantAppScanCouponQrCode = false
      if (this.couponRedeemMethod === 'MERCHANT_APP_SCAN_COUPON_QR_CODE') {
        isMerchantAppScanCouponQrCode = true
      }
      return isMerchantAppScanCouponQrCode
    },
    srk () {
      if (this.couponData && this.couponData.srk) {
        return this.couponData.srk
      }
      return ''
    }
  },
  watch: {
    '$store.state.app.OneTrustHeight': function () {
      if (!this.oneTrustHeight) {
        if (window.$('.sticky-coupon-footer')[0]) { window.$('.sticky-coupon-footer')[0].style.marginBottom = '0px' }
        if (window.$('.el-drawer')[0]) {
          window.$('.el-drawer')[0].style.marginBottom = '0px'
          window.$('.el-drawer')[0].style.height = 'auto'
          window.$('.el-drawer')[0].style.overflowY = ''
        }
        window.$('.coupon-alert-bar-dialog').css('margin-top', '15vh')
        window.$('.coupon-alert-bar-dialog .age-declaration-content').css('height', 'auto')
        // window.$('.full-dialog .el-dialog__footer').css('margin-bottom', (20 + this.oneTrustHeight) + 'px')
      }

      this.adjustSuccessPopupForOneTrust()
      // console.log('@938, height changed')
    }
  },

  beforeUpdate () {
    // this.updateCampaignDetailIfNeeded()
  },

  updated () {
    console.log('@849', this.campaignId)
    this.initCollapsed()
    const dom = document.getElementById('mobile-url-qr-code')
    this.generateMobileUrlCodeImg(dom, { width: '70' })
    this.isUpdated = true
    if (this.$store.getters['app/getIsOneTrustDisplay']) {
      if (!(this.isRedeemed && !this.hideRedeemeButton) || (window.$('.sticky-coupon-footer-button')[0] && window.$('.sticky-coupon-footer-button')[0].childElementCount)) {
        window.$('.sticky-coupon-footer').attr('style', 'margin-bottom: ' + this.oneTrustHeight + 'px')
      }
      if (this.isMiniProgram()) {
        window.$('.back-to-top-bottom').attr('style', 'bottom: calc(' + (150 + this.oneTrustHeight) + 'px + var(--sab)) !important')
      } else if (this.$store.getters['app/isMobile']) {
        window.$('.back-to-top-bottom').attr('style', 'bottom: ' + (90 + this.oneTrustHeight) + 'px !important')
      } else {
        window.$('.back-to-top-bottom').attr('style', 'bottom: ' + (20 + this.oneTrustHeight) + 'px !important')
      }
    }
    if (this.isShowPartnerQRCode) {
      this.showQrCodeImg('partner-qr-code-img')
    }

    this.adjustSuccessPopupForOneTrust()
  },

  mounted () {
    const plugin = document.createElement('script')
    plugin.setAttribute(
      'src',
      window.location.origin + '/js/html5-qrcode.min.js'
    )
    plugin.async = true
    document.head.appendChild(plugin)
    this.$store.commit('app/setNavType', 'back_language_menu')
    const { couponId, campaignId, recordId, displayIn, qty, slotId } = this.$route.query
    if (campaignId && this.$route.name !== 'campaign-detail') {
      replaceRouterWithQuery(this.$router, { name: 'page-not-found' })
    }
    this.campaignId = campaignId
    this.couponId = couponId
    this.recordId = recordId
    this.displayIn = displayIn
    this.slotId = slotId
    this.requireAmount = qty || this.goodiePerHeadDownloadLimitEachTime || this.requireAmount
    window.initQrcodeTimer = null

    this.updateCampaignDetailIfNeeded()
    this.getCouponDetail(couponId, true)

    if (campaignId && this.$route.name === 'campaign-detail') {
      this.getSystemSettings()

      if (Object.keys(this.campaignData).length !== 0) {
        sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'campaign|detail', { campaignId })
      } else {
        this.needTrackCampaignDetail = true
      }
    }

    // walty temp
    // setTimeout(() =>
    //  this.onScanSuccess('dd07acfa-e96f-11eb-a550-0a58a9feac02'), 2000)

    this.$store.commit('app/setStickyFooter')

    this.initCollapsed()

    window.$('.coupon-detail-container').on('click', '.see-more-button', function () {
      console.log('@44')
      window.$(this).parents('.is-collapsed').removeClass('is-collapsed')
    })

    const that = this

    window.addEventListener('orientationchange', function () {
      console.log('the orientation of the device is now ')
      if (that.showCodeType === 'qr-code') {
        that.initQrcode()
      }
    })

    // qrcode this.generateMobileUrlCodeImg()

    if (this.couponNotFound) {
      replaceRouterWithQuery(this.$router, { name: 'page-not-found' })
    }

    if (this.displayIn) {
      this.$nextTick(() => {
        this.showDisplayIn(0)
      })
    }
    if (this.$store.getters['app/getIsOneTrustDisplay']) {
      if (this.isMiniProgram() || this.$store.getters['app/isMobile']) {
        window.$('.el-drawer').attr('style', 'height: 60% !important')
        window.$('.el-drawer')[0].style.overflowY = 'scroll'
      }
      window.$('.el-drawer')[0].style.marginBottom = this.oneTrustHeight + 'px'
      this.adjustSuccessPopupForOneTrust()
    }
  },
  beforeUnmount () {
    // put logic inside hook of 'beforeRouteLeave'
  },
  provide () {
    return {
      closeGoodieBagSuccess: this.closeGoodieBagSuccess
    }
  },
  beforeDestroy () {
    this.$store.commit('app/setNavType', 'logo_language_menu')
    this.$message.closeAll()
    if (this.html5Qrcode) {
      setTimeout(() => {
        this.html5Qrcode.stop()
      }, 0)
    }
    window.$('.footer-main.has-sticky-footer').css('padding-bottom', '0px')
    this.enableOverflow()
  },
  methods: {
    isMiniProgram () {
      return isWechatMiniProgram()
    },
    beforeApplicableStoresClose () {
      if (!this.isShowAgeRestricted && !this.isShowApplicableStores) {
        this.enableOverflow()
      }
    },
    ageContentOkClick () {
      this.enableOverflow()
      this.isNotCancelAgeRestricted = true
    },
    getTourInfoIn2 () {
      if (!this.tourInfo) { return this.tourInfo }
      const info = [...this.tourInfo]

      // https://stackoverflow.com/questions/8495687/split-array-into-chunks
      const result = []
      const chunk = 2

      for (var i = 0; i < info.length; i += chunk) {
        const temp = [info[i], info[i + 1]]
        result.push(temp)
      }

      console.log('@1089,', info, result)
      return result
    },
    showDisplayIn (lastTime) {
      const vm = this
      const time = lastTime || 0
      setTimeout(function () {
        console.log('@1488')
        if (vm.displayIn && time <= 20000) {
          if (vm.goodiebagRecordDetail && vm.campaignData) {
            vm.$nextTick(() => {
              vm.displayIn = null
              vm.showGoodieBagSuccess(vm)
            })
          } else {
            vm.showDisplayIn(time + 1000)
          }
        }
      }, 1000)
    },
    analytics (event) {
      if (!this.tourCardIsFirstUpdated) {
        this.tourCardIsFirstUpdated = true
        return
      }
      if (this.couponId) {
        sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'coupon_detail_itinerary_swipe', 'onclick')
      } else {
        sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'offer_detail_itinerary_swipe', 'onclick')
      }
    },
    verifyDisplayIn (sectionName) {
      let result = false
      if (this.couponData && this.couponData.template && this.couponData.template.display_in === sectionName) {
        console.log('@1764 isTicket', this.couponData.template.display_in)
        result = true
      } else if (this.couponDisplayIn === sectionName) {
        result = true
      }
      return result
    },

    async updateCampaignDetailIfNeeded () {
      console.log('@893 z, update campaign detail if needed', this.campaignId, this.campaignDetailCollected, this.recordId)
      if (!this.campaignId || this.campaignDetailCollected) { return }

      let campaignNotFound = false
      const campaignInfo = this.$store.getters['campaigns/campaignDetail'](this.campaignId)

      console.log('@838, campaign info', campaignInfo)
      // if (!campaignInfo || !campaignInfo.detail) {
      console.log('@1123', this.campaignId)
      try {
        await asyncGetCampaignDetail(this.campaignId, this.recordId)

        const { qty } = this.$route.query

        this.requireAmount = qty || this.goodiePerHeadDownloadLimitEachTime || this.requireAmount

        if (!this.$store.getters['auth/isTestUser'] && !this.$route.query.tester_preview) {
          console.log('@1622', this.$route.query.tester_preview)
          this.checkCampignIsValid()
        }
        if (this.needTrackCampaignDetail) {
          sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'campaign|detail', { campaignId: this.campaignId })
          this.needTrackCampaignDetail = false
        }
      } catch (error) {
        console.log('error', error)

        console.log('371--- errro', error)
        const { data } = error.response || {}
        const { detail } = data || {}
        console.log('371--- errro', error.response, detail)
        campaignNotFound = detail === 'Not found.'
      }
      this.campaignDetailCollected = true

      if (campaignNotFound) {
        replaceRouterWithQuery(this.$router, { name: 'page-not-found' })
      }
    },
    showMobileQrCodeImageAlert () {
      this.isShowMobileQrCodeAlert = true
      setTimeout(() => {
        const dom = document.getElementById('alert-mobile-url-qr-code')
        console.log('807---- dom: ', dom)
        this.generateMobileUrlCodeImg(dom, { width: 258 })
      }, 100)
    },
    showCurrentSlide (event) {
      console.log('@1098, event---', event.currentSlide)
      const isMobile = this.$store.getters['app/isMobile']
      const totalPages = isMobile ? this.tourInfo.length : this.getTourInfoIn2().length
      if (event.currentSlide === 0) {
        this.isShowTourPreButton = false
        this.isShowTourNextButton = true
      } else if (event.currentSlide === totalPages - 1) {
        this.isShowTourPreButton = true
        this.isShowTourNextButton = false
      } else {
        this.isShowTourPreButton = true
        this.isShowTourNextButton = true
      }
    },
    bookNowClick () {
      sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'campaign|book now', { campaignId: this.campaignId })
    },
    shareCopyLink () {
      this.$clipboard(this.twitterShareContent + ' ' + this.shareUrl)
      this.showCopySuccess()
    },
    initCollapsed () {
      window.$('.is-collapsible').each((index, element) => {
        console.log('@627', window.$(element).height())
        if (window.$(element).height() >= 500) {
          window.$(element).addClass('is-collapsed')
        }
      })
    },
    closeCopyCode () {
      this.isShowCopyCoupon = false
      // track click click_no_in_use coupon confirmation pop up
      sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'click_dismiss_button_in_use_coupon_confirmation_pop_up', 'onclick', {})
    },
    cancelCopyCode () {
      this.isShowCopyCoupon = false
      // track click click_no_in_use coupon confirmation pop up
      sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'click_no_in_use_coupon_confirmation_pop_up', 'onclick', {})
    },
    async confirmCopyCode () {
      try {
        const response = await this.ajaxUserCoupon({})
        sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'coupon|use coupon success', { couponId: this.couponId, useSpecialStore: true, store: response.data.store || {}})
      } catch (error) {
        console.log('error: ', error.response)

        this.isInCheckUseCoupon = false
        this.checkUseCouponFailHandle(error)

        sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'coupon_use_coupon_fail', 'event')
        sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'coupon_detail_use_coupon_fail', 'event', { method: this.couponData.template.redemption_method })
        sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'coupon|use coupon fail', { couponId: this.couponId, errorMessage: this.$t(this.goodieBagFailText), useSpecialStore: true })
      }

      this.isShowCopyCoupon = false
      if (this.isStarred) {
        getCampaignlimit(this.$store)
      }
      // track click click_yes_in_use coupon confirmation pop up
      sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'click_yes_in_use_coupon_confirmation_pop_up', 'onclick', {})
      sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'coupon_use_coupon_success', 'event', { coupon_qty: 1 })
      sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'coupon_detail_use_coupon_success', 'event', { method: this.couponData.template.redemption_method, coupon_qty: 1 })
    },
    copyCouponCode () {
      console.log('copy code')
      this.$clipboard(this.couponCode)
      if (this.couponCode.startsWith('http')) {
        this.showShortLinkCopySuccess()
      } else {
        this.showCodeCopySuccess()
      }
    },
    copyCouponCodeUrl () {
      console.log('copy code url')
      this.$clipboard(this.couponCodeUrl)
      this.showShortLinkCopySuccess()
    },
    clickToShowFullPartnerBarCodeDialogDesktop () {
      this.showFullPartnerBarCodeDialogDesktop = true
      // track click click_enlarge_button_to_barcode_images_in_coupon_details
      sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'click_enlarge_button_to_barcode_images_in_coupon_details', 'onclick', {})
    },
    useCouponCodeUrl () {
      if (this.couponCodeUrl) {
        if (this.isMiniProgram()) {
          this.copyCouponCodeUrl()
        } else {
          window.open(this.couponCodeUrl)
        }
      } else {
        if (this.isMiniProgram()) {
          this.copyCouponCode()
        } else {
          window.open(this.couponCode)
        }
      }
    },
    toggleBrandDescription () {
      if (this.brandDescription) {
        this.isShowBrandDescription = !this.isShowBrandDescription
      }
    },
    takeSurvey () {
      pushRouterWithQuery(this.$router, { name: 'feedback', query: { couponId: this.couponData.id }})
    },
    generateMobileUrlCodeImg (dom, config) {
      const QRCode = require('qrcode')
      console.log('@834----- window.location.href', window.location.href)
      QRCode.toCanvas(dom, window.location.href, config, function (error) {
        console.log('generate url code error: ', error)
      })
    },
    gotoWalletURL () {
      const lang = getLangName(this.$i18n.locale)

      const qty = this.requireAmount

      let verifyUrl = `${process.env.VUE_APP_WALLET_URL}/verify/?campaign=${this.campaignId}&lang=${lang}&goodiebag=${this.recordId}&qty=${qty}`

      const { slotId } = this.$route.query
      if (slotId) { // for goodiebag multiple slot
        verifyUrl += '&slotid=' + slotId
      }

      if (this.eventName) {
        verifyUrl += '&event=' + this.eventName
      }

      const isLogin = localStorage.getItem('refreshToken')

      if (isWechatMiniProgram() && !isLogin) {
        loginSSO(verifyUrl)
        return
      }

      if (isWechatMiniProgram()) {
        verifyUrl += `&aaid=${this.$store.getters['app/getWechatAdobeAid']}&platform=wechat`
      }

      verifyUrl = setupDefaultQueryString(this.$route, verifyUrl)

      window.location.href = verifyUrl
    },
    async showGoodieBagSuccess (vm) {
      try {
        await this.$store.dispatch('goodiebagparticipationrecords/updateRecord', vm.recordId)
      } catch (error) {
        console.log('error--', error)
      }
      this.goodieBagDrawer = false
      if (this.$store.getters['app/isMobile']) {
        this.isShowGoodieBagSuccess = true
      } else {
        const query = {
          'campaignId': vm.campaignId,
          'recordId': vm.recordId,
          'count': vm.requireAmount,
          'type': vm.isGoodieBagVoucher ? 'CASH_VOUCHER' : 'GIFT',
          'goodiecreditsorquota': this.isUnlimitedReward ? 'un' : vm.goodieCreditsOrQuota,
          'desired_coupon_publish_channel': getChannelName(),
          'name': vm.name,
          'slotId': vm.slotId,
          'coupondisplayin': vm.couponDisplayIn
        }
        pushRouterWithQuery(vm.$router, { name: 'acquires-credits-success-page', query })
      }
    },
    async checkCampignIsValid () {
      const ssoUid = this.$store.getters['auth/ssoUid']
      if (!ssoUid) {
        return
      }

      const postData = this.getParticiationPayload()
      let response

      let event_query = ''
      if (this.eventName) {
        event_query = `&event=${this.eventName}`
      }

      try {
        response = await axios.post(getCampaignAPIPrefix() + `/customers/${ssoUid}/campaign_participation_records?check_link=true${event_query}`, postData)

        const grecaptcha = window.grecaptcha
        const recaptchaContainer = document.getElementById('robot')
        console.log('@2053a', grecaptcha, recaptchaContainer)

        if (grecaptcha && recaptchaContainer) {
          console.log('@2053, start to do recatptcha')
          grecaptcha.enterprise.render(recaptchaContainer, {
            sitekey: process.env.VUE_APP_RECACPTCHA_V3_KEY,
            size: 'invisible'
          })
          // const footerHight = window.$('.sticky-coupon-footer.sticky-campagin-button').css('height')
          // window.$('.grecaptcha-badge').css('bottom', footerHight)
          // window.$('.grecaptcha-badge').css('clip-path', 'inset(-5px -5px 0px -5px)')
        }
      } catch (error) {
        response = error.response
        console.log('error for checking: @1732', response)
        // TODO: show error message
        if (response && response.data) {
          this.checkLinkErrorMessageDict = response.data

          // console.log('@1937', this.canGoBack(), this.$root.detailNavigated)
          if (!this.$root.detailNavigated && !this.displayIn) { // if click into this page, don't show the error, requested by designer
            this.showCampaignErrorDialog = true
          }
        }
      }
    },
    async getSystemSettings () {
      const response = await axios.get(`/settings`)
      if (response && response.data) {
        this.isVerifyRecaptcha = response.data.recaptcha_open
      } else {
        this.isVerifyRecaptcha = true
      }
    },
    validateCaptcha () {
      return new Promise((res, rej) => {
        if (!window.grecaptcha || !document.getElementById('robot').hasChildNodes()) {
          // rej(new Error('Recaptcha not available'))
          return res('Recaptcha not available')
        }
        window.grecaptcha.enterprise.ready(() => {
          window.grecaptcha.enterprise.execute({ action:
                 'login' }).then((token) => {
            console.log('@2069', token)
            return res(token)
          })
        })
      })
    },
    getParticiationPayload (recaptchaString) {
      var postData
      const profile = this.$store.getters['auth/userProfile']

      console.log('@1732b', this.isGoodieBagVoucher, this.isGoodieBagGift)
      if (this.isGoodieBagVoucher || this.isGoodieBagGift) {
        postData = {
          campaign: parseInt(this.campaignId),
          customer: profile.id,
          desired_coupon_quantity: this.requireAmount,
          desired_coupon_goodie_bag_participation_record: parseInt(this.recordId),
          desired_coupon_publish_channel: getChannelName(),
          desired_coupon_goodie_bag_campaign_slot: parseInt(this.slotId)
        }
      } else {
        postData = {
          campaign: parseInt(this.campaignId),
          customer: profile.id,
          desired_coupon_quantity: this.requireAmount,
          desired_coupon_publish_channel: getChannelName()
        }
      }

      if (recaptchaString || recaptchaString === '') {
        Object.assign(postData, { recaptcha_verify_credential: recaptchaString })
      }

      return postData
    },
    isQueueitError () {
      let isQueueit = false

      if (window.queueitEnabled) {
        const queueItVerified = window.queueItVerified
        let queueItVerifiedCookie
        const queueItVerifiedCookieName = window.queueItVerifiedCookieName
        if (queueItVerifiedCookieName) {
          queueItVerifiedCookie = Cookies.get(queueItVerifiedCookieName)
        }
        if (!queueItVerified || !queueItVerifiedCookie) {
          isQueueit = true
        }
      }
      return isQueueit
    },
    async getCoupon () {
      console.log('@2199', this.campaignData.show_coupon_download_confirmation)
      if (!this.isReminder && this.campaignData.show_coupon_download_confirmation) {
        this.isReminder = true
        return
      } else if (this.isQueueitError()) {
        this.isShowQueueError = true
        return
      }

      if (!this.isCampainActive || this.hasGotCoupon) {
        return
      }

      // if (this.isQueueitError()) {
      //   this.isShowQueueError = true
      //   return
      // }

      const needVerifyCampaign = this.template && this.template.coupon_campaign_type_coupon_template &&
        this.template.coupon_campaign_type_coupon_template.require_hkid_or_passport_number
      console.log('881---- need verify campaign', needVerifyCampaign)

      if (needVerifyCampaign) {
        console.log('campagin id:', this.campaignId)
        this.gotoWalletURL()
        return
      }

      const ssoUid = this.$store.getters['auth/ssoUid']
      const vm = this

      if (ssoUid) {
        console.log('420------- ssoUid', ssoUid)

        if (this.isGoodieBagVoucher || this.isGoodieBagGift) {
          if (!this.recordId) {
            return
          }
          this.isAcquiring = true
        } else {
          this.isLoading = true
        }

        let recaptchaString = null

        if (this.isVerifyRecaptcha) {
          const token = await this.validateCaptcha()

          try {
            const { data } = await axios.post(`/customers/verify-recaptcha`,
              { token, for: 'acquire' }
            )

            console.log('@2260b', data)
            recaptchaString = data.recaptcha_verify_credential
            if (!recaptchaString) {
              this.isShowRecaptchaFail = true
              this.recaptchaFailTitle = this.$t('recaptcha_low_score_title')
              this.recaptchaFailContent = this.$t('recaptcha_low_score_content')
            } else {
              this.isShowRecaptchaFail = false
            }
          } catch (error) {
            this.isShowRecaptchaFail = true
            this.recaptchaFailTitle = this.$t('recaptcha_low_score_title')
            this.recaptchaFailContent = this.$t('recaptcha_fail_content')
            console.log('@2269 error while getting recaptcha', error)
          } finally {
            this.isLoading = false
            this.isAcquiring = false
          }
        }
        if (this.isShowRecaptchaFail) {
          return
        }

        console.log('@2260', recaptchaString)
        const postData = this.getParticiationPayload(recaptchaString)

        let event_query = ''
        if (this.eventName) {
          event_query = `?event=${this.eventName}`
        }

        axios.post(getCampaignAPIPrefix() + `/customers/${ssoUid}/campaign_participation_records${event_query}`, postData).then(response => {
          let collectedQty = 0

          console.log('@2142', response)
          if (vm.isGoodieBagVoucher || this.isGoodieBagGift) {
            vm.showGoodieBagSuccess(vm)
          } else {
            const displayIn = this.template && this.template.coupon_campaign_type_coupon_template
              ? this.template.coupon_campaign_type_coupon_template.display_in : ''
            vm.showGetSuccessfullMessage(true, displayIn)
            this.hasGotCoupon = true
            setTimeout(() => { this.hasGotCoupon = false }, 3000)
          }
          collectedQty = response.data.desired_coupon_quantity

          this.$store.dispatch('coupons/refreshCoupons')
          sendManualAnalytics(this.$route, this.$i18n.locale, this.$store,
            'acquire_coupon_success', 'event', { coupon_qty: collectedQty })
          if (this.isStarred) {
            getCampaignlimit(this.$store)
          }
          sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'campaign|add to wallet', { campaignId: this.campaignId })
        }).catch(error => {
          console.log('@695--- detail', error)
          const { data } = error.response
          const display_error_content = data.display_error_content
          const detail = data.detail
          if (vm.isGoodieBagVoucher || this.isGoodieBagGift) {
            if (display_error_content === 'Not enough credit') {
              vm.goodieBagFailText = 'Desired ticket(s) are sold out.'
              vm.isShowGoodieBagFail = true
            } else {
              vm.goodieBagFailText = display_error_content
              vm.isShowGoodieBagFail = true
            }
            sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'campaign|add to wallet fail', { campaignId: this.campaignId, errorMessage: vm.goodieBagFailText })
          } else {
            if (data && data.detail && data.detail.includes('required hkid')) { // high value coupon but not detected
              this.gotoWalletURL()
            } else {
              vm.goodieBagFailText = display_error_content
              vm.isShowGoodieBagFail = true
              sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'campaign|add to wallet fail', { campaignId: this.campaignId, errorMessage: vm.goodieBagFailText })
              // let offset = 77
              // if (window.$('.is-vue-mobile').length) {
              //  offset = 73
              // }
              // if (window.$('.nav').height() === 0) {
              //  offset = 10
              // }
              // this.$message({
              // dangerouslyUseHTMLString: true,
              //  message: `<strong><img src='/img/error_icon.png' width='18px' height='18px'>${display_error_content}</strong>`,
              //  customClass: 'server-error',
              //  type: '',
              //  duration: 5000,
              //  offset: offset
              // })
            }
          }
          sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'acquire_coupon_fail_detail', 'onclick', { 'detail': detail })
        }).finally(() => {
          this.isLoading = false
          this.isAcquiring = false
        })
      } else {
        /*
        const state = {
          'url': `${window.location.pathname}${window.location.search}`,
          'info': {
          }
        }
        // const ssoAuth = getSsoAuthClient(JSON.stringify(state))
        window.open(ssoAuth.code.getUri(), '_self')
        */
        loginSSO()
      }
    },
    showQrCodeImg (elementId = 'qr-canvas') {
      let canvasCode = this.barcodeValue
      if (elementId === 'qr-canvas') {
        this.isShowQrCanvas = true
        this.qrCodeWidth = '330'
        canvasCode = this.qrCodeCouponCode
      } else if (elementId === 'partner-qr-code-img') {
        this.qrCodeWidth = '180'
        if (this.$store.getters['isMobile']) {
          this.qrCodeWidth = '300'
        }
      }
      const QRCode = require('qrcode')
      const canvas = document.getElementById(elementId)
      QRCode.toCanvas(canvas, canvasCode, { width: this.qrCodeWidth }, function (error) {
        if (error) console.error(error)
      })
      if (elementId === 'qr-canvas') {
        window.scrollTo(0, 0)
      }
    },
    hideQrCodeImg () {
      this.isShowQrCanvas = false
    },
    openApplicableStores () {
      if (this.campaignId) {
        sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'campaign|applicable stores', { campaignId: this.campaignId })
      } else if (this.couponId) {
        sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'coupon|applicable stores', { couponId: this.couponId })
      }

      if (window.$('.is-vue-mobile').length) {
        console.log('360 campaign id: ', this.campaignId)
        const query = Object.assign({}, this.$route.query)
        if (this.campaignId) {
          query['campaignId'] = this.campaignId
        } else {
          query['couponId'] = this.couponId
        }

        pushRouterWithQuery(this.$router, { name: 'applicable-store-list', query: query })
      } else {
        this.isShowApplicableStores = !this.isShowApplicableStores
        this.disableOverflow()
        this.applicableStoresPageType = 'list'
      }
    },
    pageTypeChange (type) {
      this.applicableStoresPageType = type
    },
    async ajaxCheckCoupon (payload) {
      const response = await axios.get(`/coupons/check_valid?id=${this.couponId}`, payload)
      const { data } = response
      console.log('response', response, data)
      // this.$store.commit('coupons/updateCoupon', coupon)
      return response
    },
    checkUseCouponFailHandle (error) {
      if (error.response.data.detail === 'error_use_coupon_unreach_special_time_coupon') {
        this.goodieBagFailTitle = 'couponNotAvailableInSpecificDaysPromptTitle'
        this.goodieBagFailText = this.$t('couponNotAvailableInSpecificDaysPrompt')
        this.isShowGoodieBagFail = true
      } else if (error.response.data.detail === 'Coupon is expired.') {
        this.goodieBagFailTitle = 'couponInvalidPromptTitle'
        this.goodieBagFailText = this.$t('couponInvalidPrompt')
        this.useCouponFailToMyWallet = true
        this.isShowGoodieBagFail = true
      } else if (error.response.data.detail === 'Coupon is used.') {
        this.goodieBagFailTitle = 'couponInvalidPromptTitle'
        this.goodieBagFailText = this.$t('couponUsedPrompt')
        this.useCouponFailToMyWallet = true
        this.isShowGoodieBagFail = true
      } else if (error.response.data.detail === 'Coupon is unavailable.') {
        this.goodieBagFailTitle = 'couponUnavailablePromptTitle'
        this.goodieBagFailText = this.$t('couponNotInActivePeriodPrompt')
        this.isShowGoodieBagFail = true
        this.couponData.effective_date = error.response.data.effective_date
      } else if (error.response.data.detail === 'Coupon is inactive') {
        this.goodieBagFailTitle = 'couponUnavailablePromptTitle'
        this.goodieBagFailText = this.$t('couponInactivePrompt')
        this.useCouponFailToMyWallet = true
        this.isShowGoodieBagFail = true
      }
    },
    async useCoupon () {
      console.log('518 item: ', this.couponData)
      if ((!isAvailable(this.couponData) && !this.isMerchantAppScanCouponQrCode) || !this.isInUsedTime) {
        return
      }
      sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'coupon|use coupon', { couponId: this.couponId })
      try {
        await this.ajaxCheckCoupon({})
      } catch (error) {
        this.isInCheckUseCoupon = true
        this.checkUseCouponFailHandle(error)
        sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'coupon|use coupon fail', { couponId: this.couponId, errorMessage: this.$t(this.goodieBagFailText), useSpecialStore: true })
        return
      }

      if (this.canShowCopyCodeButton || this.canShowUseCouponButton) {
        this.isShowCopyCoupon = true
      } else if (this.couponRedeemMethod === 'MERCHANT_APP_SCAN_COUPON_QR_CODE') {
        this.showQrCodeImg()
      } else if (this.couponRedeemMethod === 'USERS_SCAN_STORE_QR_CODE') {
        if (!this.isUseWechatCamera()) {
          this.showCodeType = 'qr-code'
        }

        this.$store.commit('app/setNeedShowBackToTop', false)
        this.initQrcode()
      }
      // this.hideQrCodeImg()

      if (this.isShowCopyCoupon) {
        // track PV coupon_detail_use_coupon_confirmation_popup
        sendPageViewAnalytics(this.$route, this.$i18n.locale, this.$store, { popupType: 'coupon_detail_use_coupon_confirmation_popup' })
      }
    },
    isUseWechatCamera () {
      return isWechatMiniProgram() && process.env.VUE_APP_WECHAT_OFFICIAL_ACCOUNT_APP_ID
    },
    getMobileOperatingSystem () {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return 'Windows Phone'
      }

      if (/android/i.test(userAgent)) {
        return 'Android'
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS'
      }

      return 'unknown'
    },
    async _initQrcode () {
      if (this.html5Qrcode) {
        this.html5Qrcode.stop()
      }
      const config = {
        fps: 10,
        qrbox: 250,
        aspectRatio: 1.777778
      }
      console.log('487------ uri init qrcode')
      this.html5Qrcode = new window.Html5Qrcode('qr-code-full-region')
      console.log(this.html5Qrcode)
      this.lockScan = false

      // if (true || this.getMobileOperatingSystem() === 'Android') {
      /*
      try {
        // https://stackoverflow.com/questions/11642926/stop-close-webcam-stream-which-is-opened-by-navigator-mediadevices-getusermedia
        window.navigator.mediaDevices.getUserMedia({ video: true })
          .then(stream => {
            stream.getTracks().forEach((track) => {
              track.stop()
            })
          })
          .catch((err) => {
            console.log('@2311', err)
          })
      } catch (error) {
        console.log('@2315,, failed to close media', error)
      }
      */
      // }

      if (this.isUseWechatCamera()) {
        this.isOpeningCamera = true
        await this.wechatOpenCamera((result) => {
          console.log('@2384', result)
          // window.alert('@2383: ' + JSON.stringify(result).substring(0, 255))
          this.onScanSuccess(result.resultStr)
        }, (errWechat) => {
          console.log('failed to open wechat camera', errWechat)
          this.sendCameraErrorMessage(errWechat)
        })
        this.close()
        this.isOpeningCamera = false
      } else {
        this.disableOverflow()

        // this.showCodeType = 'qr-code'

        this.html5Qrcode.start(
          { facingMode: 'environment' }, config, this.onScanSuccess, this.onScanError
        ).catch((err) => {
          console.log('@1324 failed to open webview camera', err)
          console.log('@1324b trying to start wechat camera now')

          this.sendCameraErrorMessage(err)

          this.close()
        })
      }
    },
    sendCameraErrorMessage (error, errorWechat) {
      let campaignId = this.campaignId
      if (!campaignId && this.couponData && this.couponData.campaign) {
        campaignId = this.couponData.campaign.id
      }
      console.log('@1324b', campaignId)
      let cameraErrorMassage = JSON.stringify(error)
      if (errorWechat) {
        cameraErrorMassage += '; ' + JSON.stringify(errorWechat)
      }
      sendManualAnalytics(this.$route, this.$i18n.locale, this.$store,
        'coupon_camera_fail', 'event',
        { id: campaignId, camera_errormsg: cameraErrorMassage })
      if (cameraErrorMassage && cameraErrorMassage.includes('NotAllowedError')) {
        this.logCustomerActivityLog('COUPON_USE_FAIL_WITH_CAMERA_PERMISSION_DECLINED')
      }
    },
    async logCustomerActivityLog (activityType) {
      try {
        const ssoUid = this.$store.getters['auth/ssoUid']
        const response = await axios.post(`/customers/${ssoUid}/activity_logs`, { activity_type: activityType })
        console.log('@logCustomerActivityLog', response)
      } catch (error) {
        console.log('@logCustomerActivityLog', error)
      }
    },
    async onScanSuccess (qrCodeMessage) {
      console.log('@2680x qr code message', qrCodeMessage, this.couponData, this.lockScan)

      if (this.lockScan) {
        return
      }

      console.log('@2680a')

      let response = null
      this.lockScan = true

      try {
        response = await this.ajaxUserCoupon({ store_qr_code: qrCodeMessage })

        console.log('@2610', response)
        sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'coupon|use coupon success', { couponId: this.couponId, useSpecialStore: true, storeData: response.data.store || {}})
      } catch (error) {
        console.log('error')
        if (isDev()) {
          console.log('Error for scan: ' + JSON.stringify(error))
        }

        sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'coupon_use_coupon_fail', 'event')
        sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'coupon_detail_use_coupon_fail', 'event', { method: this.couponData.template.redemption_method })

        if (isWechatMiniProgram()) {
          // show the gray area if need to show qr code error page
          this.showCodeType = 'qr-code'
        }

        this.isInCheckUseCoupon = false

        let errorMessage = null
        if (['error_use_coupon_unreach_special_time_coupon', 'Coupon is expired.', 'Coupon is used.', 'Coupon is inactive', 'Coupon is unavailable.'].indexOf(error.response.data.detail) >= 0) {
          this.checkUseCouponFailHandle(error)
          errorMessage = this.$t(this.goodieBagFailTitle)
        } else {
          this.isQrcodeErrorDialog = true
          errorMessage = this.$t('myWalletInvalidQRcodeLineOne')
        }
        sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'coupon|use coupon fail', { couponId: this.couponId, errorMessage, useSpecialStore: true })

        return
      }

      this.lockScan = false

      if (this.html5Qrcode) { this.html5Qrcode.stop() }
      const { data } = response
      // const data = testData
      console.log('@2630', data)
      const { coupon, store, used_at } = data
      console.log('response-=----', coupon)
      this.$store.commit('coupons/updateCoupon', coupon)
      this.redeemedTime = this.getDateString(used_at, 'D MMM YYYY HH:mm:ss')
      this.storeInfo = store
      if (this.couponTemplateType === 'IMPORT_EXISTING_COUPON') {
        this.isScanCodeSuccessDialog = true
        setTimeout(() => { this.showQrCodeImg('barcode-qr-canvas') }, 0)
      } else {
        this.isScanCodeSuccessDialog = true
      }

      this.showCodeType = 'coupon-detail'
      this.hideRedeemeButton = true
      this.$store.commit('app/setNeedShowBackToTop', true)
      if (this.isStarred) {
        getCampaignlimit(this.$store)
      }
      this.enableOverflow()
      sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'coupon_use_coupon_success', 'event', { coupon_qty: 1 })
      sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'coupon_detail_use_coupon_success', 'event', { method: this.couponData.template.redemption_method, coupon_qty: 1 })

      this.adjustSuccessPopupForOneTrust()
    },
    adjustSuccessPopupForOneTrust () {
      console.log('@2694, adjust for onetrust')
      window.$('.el-dialog.full-dialog .scan-code-success-buttons').css('margin-bottom', this.oneTrustHeight + 'px')
      window.$('.el-dialog.full-dialog .scan-code-success-dialog-content').css('margin-bottom', this.oneTrustHeight + 'px')
      window.$('.barcode-full-screen-content canvas').css('margin-bottom', this.oneTrustHeight + 'px')
      window.$('#qr-code-full-region').css('bottom', (this.oneTrustHeight - 15) + 'px')
      this.adjustAgeRestrictedPopupForOneTrust()
    },
    onScanError (errorMessage) {
      // console.log('5470----- error Message', errorMessage)
      // this.isQrcodeErrorDialog = true
      // this.error = errorMessage
    },
    async ajaxUserCoupon (payload) {
      const response = await axios.post(`/coupons/${this.couponId}/usage_record`, payload)
      const { data } = response
      const { coupon } = data
      console.log('response', response, data, coupon)
      this.$store.commit('coupons/updateCoupon', coupon)
      return response
    },
    closeInvalidDialog () {
      this.isQrcodeErrorDialog = false
      this.lockScan = false

      if (isWechatMiniProgram()) {
        // for mini program, close error dialog also close the error message
        this.showCodeType = 'coupon-detail'
      }
    },
    close () {
      setTimeout(() => {
        if (this.html5Qrcode) {
          this.html5Qrcode.stop()
        }
      }, 0)
      this.showCodeType = 'coupon-detail'
      this.$store.commit('app/setNeedShowBackToTop', true)
      this.enableOverflow()
    },
    goodieBagReduce () {
      if (this.requireAmount > this.goodiePerHeadDownloadLimitEachTime) {
        this.requireAmount -= this.goodiePerHeadDownloadLimitEachTime
      }
    },
    goodieBagAdd () {
      if (!this.isAddDisable()) {
        this.requireAmount += this.goodiePerHeadDownloadLimitEachTime
      }
    },
    acquireGoodieBag () {
      if (this.couponStock === 0) {
        this.goodieBagFailText = 'Desired ticket(s) are sold out.'
        this.isShowGoodieBagFail = true
        return
      }
      if (this.isQueueitError()) {
        this.isShowQueueError = true
        return
      }
      this.goodieBagDrawer = true
    },
    payGoodieBag () {
      if (!this.isAcquiring) {
        this.getCoupon()
      }
    },
    closeGoodieBagSuccess () {
      this.isShowGoodieBagSuccess = false
      const { goodie_bag: goodieBag } = this.goodiebagRecordDetail || {}
      const query = {
        'type': this.campaignType,
        'recordId': this.recordId,
        'goodieBagId': goodieBag && goodieBag.id,
        'slotId': this.slotId
      }

      pushRouterWithQuery(this.$router, { name: 'goodie-bag-campaign-list', query })
    },
    isAddDisable () {
      const goodiePerHeadOwnLimit = this.goodiePerHeadOwnLimit === null ? BIG_NUMBER : this.goodiePerHeadOwnLimit
      // console.log('@2385x', this.template.coupon_count)
      return (!this.isUnlimitedReward && this.goodieBagCost * (this.requireAmount + this.goodiePerHeadDownloadLimitEachTime) > this.goodieCreditsOrQuota) ||
        this.requireAmount >= this.goodieDownloadQuota ||
        this.goodieOwnedCount + this.requireAmount >= goodiePerHeadOwnLimit ||
        (this.couponStock && this.requireAmount >= this.couponStock) || this.getCouponStockMessage
    },
    closeRecaptchaError () {
      this.isShowRecaptchaFail = false
      this.goodieBagDrawer = false
    },
    closeGoodieBagError () {
      if (!this.couponData) {
        this.checkCampignIsValid()
      }
      this.isShowGoodieBagFail = false
      this.goodieBagDrawer = false

      if (this.couponData) {
        // after scan qrcode
        if (this.isInCheckUseCoupon) {
          if (this.couponData.template.redemption_method === 'USERS_SCAN_STORE_QR_CODE') {
            this.lockScan = false

            if (isWechatMiniProgram()) {
              // for mini program, close error dialog also close the error message
              this.showCodeType = 'coupon-detail'
            }
          }
        } else if (this.couponData.template.redemption_method === 'USERS_SCAN_STORE_QR_CODE') {
          if (this.html5Qrcode) { this.html5Qrcode.stop() }
          this.showCodeType = 'coupon-detail'
          this.$store.commit('app/setNeedShowBackToTop', true)
          this.enableOverflow()
        }

        if (this.useCouponFailToMyWallet) {
          this.$store.dispatch('coupons/refreshCoupons')
          pushRouterWithQuery(this.$router, { name: 'my-wallet-all' })
          this.useCouponFailToMyWallet = false
        } else if (this.goodieBagFailTitle === 'couponNotAvailableInSpecificDaysPromptTitle') {
          this.couponData.within_used_time = false
        }
      }
    },
    showBarCodeDialog () {
      this.isShowBarCodeDialog = true
      if (this.formats === 'COUPON_QR_CODE') {
        setTimeout(() => { this.showQrCodeImg('barcode-qr-canvas') }, 0)
      }
    },
    initQrcode () {
      clearTimeout(window.initQrcodeTimer)
      window.initQrcodeTimer = setTimeout(() => {
        this._initQrcode()
      }, 500)
    },
    closeQueueError () {
      this.isShowQueueError = false
      location.reload()
    },
    reminderOkClick () {
      this.getCoupon()
      this.isReminder = false
    },
    reminderCancelClick () {
      this.isReminder = false
    },
    getCouponDetail (couponId, firstLoad = false) {
      if (couponId) {
        const ssoUid = this.$store.getters['auth/ssoUid']
        axios.get(`/customers/${ssoUid}/coupons/${couponId}`).then(response => {
          console.log('response---', response)
          this.$store.commit('coupons/updateCoupon', response.data)

          if (response.data && response.data.template.show_color_indicator) {
            this.isShowCouponIndicator = true
            this.couponIndicatorColor = response.data.template.indicator_color
            this.couponIndicatorText = response.data.template.indicator_text
          } else if (response.data && response.data.goodie_bag && response.data.goodie_bag.show_color_indicator) {
            this.isShowCouponIndicator = true
            this.couponIndicatorColor = response.data.goodie_bag.indicator_color
            this.couponIndicatorText = response.data.goodie_bag.indicator_text
          }

          if (firstLoad) {
            sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'coupon|detail', { couponId })
          }
        }).catch(error => {
          console.log('371--- errro', error)
          const { data } = error.response || {}
          const { detail } = data || {}
          console.log('371--- errro', error.response, detail)
          this.couponNotFound = detail === 'Not found.'
        })
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    console.log('@100, unset sticky footer')
    this.$store.commit('app/unsetStickyFooter')
    this.getCouponDetail(this.couponId)
    next()
  }
}
</script>
<style lang='scss' scoped>
@import '@/assets/carousel.scss';
@import "@/assets/coupon-item.scss";

.is-vue-mobile, .is-vue-desktop{
  .coupon-offer-labels{
    top: 20px;
    left: -6px;
  }
  .coupon-offer-label-shadow{
    width: 6px;
    height: 6px;
  }
  .coupon-offer-label-font{
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    height: 34px;
  }
  .coupon-offer-label-font{
    padding-left: 12px;
    padding-right: 7px;
  }
  .coupon-offer-label-right-image{
    background-image: -webkit-image-set(
      url("/img/offer-label-right.1x.png") 1x,
      url("/img/offer-label-right.2x.png") 2x,
      url("/img/offer-label-right.3x.png") 3x
    );
    background-image: image-set(
      url("/img/offer-label-right.1x.png") 1x,
      url("/img/offer-label-right.2x.png") 2x,
      url("/img/offer-label-right.3x.png") 3x);
    }
}

.main-container .low-label{
  position: absolute;
  bottom: 0px;
  left: 0;
  height: 40px;
  padding-left: 14px;
  padding-right: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #05218A;
  border-radius: 0px 10px 0px 0px;
  div{
    font-weight: 700;
    font-size: 24px;
    color: #FFFFFF;
    text-transform: uppercase;
    margin-left: 0px;
    margin-right: 0px;
  }
}

.main-container .plural.low-label{
  bottom: 2px;
}

.no-data{
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
}

.qr-container.is-hidden-mobile{
  cursor: pointer;
}
.qr-ticket-container.show-web-ticket{
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #000000;
}

.usage-detail{
  white-space: normal;
  color: #000;
  font-size: 15px;
}

.scan-code-success-footer{
  display: flex;
  flex-flow: column;
  align-items: center;
}

.is-vue-desktop .coupon-detail-url{
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 24px;
  padding-left: 16px;
  padding-right: 16px;
}

.coupon-detail-mobile-url, .coupon-detail-url{
  padding-left: 25px;
  padding-right: 16px;
  text-align: center;
  color: #8B8E96;
  font-weight: 700;
  font-size: 20px;
  padding-top: 18px;
  padding-bottom: 19px;
  border-bottom: 1px dashed #979797;
  word-break: break-all;
  div{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.web-get-coupon-container {
  background-color: #fff;
  padding-left: 20px;
  padding-top: 20px;
  padding-right: 24px;
  padding-bottom: 20px;
  margin-bottom: 20px;

  .web-get-coupon-title {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #4A4A4A;
  }

  .web-get-coupon-button {
    margin-top: 32px;
    width: 260px;
    height: 48px;
    font-size: 18px;
    color: #FFFFFF;
    border-radius: 41px;
    font-weight: bold;
    line-height: 48px;
    text-align: center;
    cursor: pointer;
  }

  .web-get-coupon-button.coupon-unavailable {
    background: #E2002C;
    opacity: 0.3;
    cursor: default;
  }
}

.goodie-bag-footer-gift{
  .title{
    margin-bottom: 8px;
  }
  .credits-count{
    display: flex;
    align-items: center;
    .credits{
      display: flex;
      align-items: center;
    }
    .reduce, .add{
      border: 1.5px solid #E2002C;
      height: 34px;
      width: 34px;
      border-radius: 17px;
      color: #E2002C;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 100;
      cursor: pointer;
      user-select: none;
    }
      .add{
        font-size: 28px;
        margin-left: 12px;
      }
      .reduce.disable, .add.disable{
        border: 1.5px solid #C2C2C2;
        color: #C2C2C2;
        cursor: unset;
      }
      .reduce{
        font-size: 28px;
        padding-bottom: 5px;
        margin-right: 12px;
      }
      .count{
        font-weight: bold;
        font-size: 20px;
      }
  }
}

.web-get-coupon-container.goodie-bag{
  padding: 15px;
  .title{
    font-size: 13px;
    color: #000000;
  }
  .num{
    font-weight: bold;
    font-size: 30px;
    color: #FF5C00;
  }
  .credits{
    display: flex;
  }
  hr{
    margin-top: 17px;
    margin-bottom: 0px;
  }
  .web-get-coupon-button{
    margin-top: 15px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .web-get-coupon-button.coupon-unavailable{
    font-size: 16px;
  }
  .web-get-coupon-title{
    font-weight: normal;
    color: #000;
  }
  .credits-count{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title{
      margin-bottom: 0px;
      font-weight: normal;
    }
    .credits{
      display: flex;
      align-items: center;
    }
    .reduce, .add{
      border: 1.5px solid #E2002C;
      height: 34px;
      width: 34px;
      border-radius: 17px;
      color: #E2002C;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 100;
      cursor: pointer;
      user-select: none;
    }
      .add{
        font-size: 28px;
        margin-left: 12px;
      }
      .reduce.disable, .add.disable{
        border: 1.5px solid #C2C2C2;
        color: #C2C2C2;
        cursor: unset;
      }
      .reduce{
        font-size: 28px;
        padding-bottom: 5px;
        margin-right: 12px;
      }
      .count{
        font-weight: bold;
        font-size: 20px;
      }
  }
}

.collpase-arrow{
  cursor: pointer;
}

.is-collapsed {
  overflow-y:hidden;
}
.is-collapsible {
  position: relative;
}

.coupon-detail-use-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.is-vue-mobile .el-dialog.coupon-use-confirm-dialog .coupon-detail-use-buttons{
  justify-content: center;
}

.share-container {
  margin-top: 10px;
  .share-title {
    font-size: 20px;
    line-height: 27px;
    font-weight: bold;
    color: #05218A;
    margin-bottom: 11px;
  }

  .share-button img{
    height: 44px;
  }

  .share-button:not(:first-child) {
    margin-left: 13.6px;
  }
}

.brand-description {
    margin-top: 20px;
    padding: 20px;
    font-size: 15px;
    background: #F1F1F1;
    margin-left: 20px;
    margin-right: 20px;
    white-space: normal;
  }

.coupon-detail-use-button {
  cursor: pointer;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #E2002C;
  padding: 12px 15px;
  border: 2px solid #E2002C;
  border-radius: 100px;
  box-sizing: border-box;
  min-width: 100px;
}

.coupon-detail-cancel-confirm-button {
  color: #fff;
  background: #E2002C;
  margin-left: 20.5px;
}

.coupon-use-confirm-dialog-content {
  font-size: 15px;
  line-height: 23px;
  color: #404040;
  word-break: break-word;
}

.coupon-detail-use-title {
  font-weight: bold;
  font-size: 30px;
  color: #4A4A4A;
  line-height: 41px;
  text-align: left;
}

.brand-arrow-icon {
  width: 11px;
}

.store-arrow-icon {
  width: 5.47px;
}

.collpase-button-container {
  position: absolute;
  width: 100%;
  bottom: 0;
  display: none;
  justify-content: center;
  align-items: flex-end;
  height: 124px;
  background: linear-gradient(360deg, rgba(255, 255, 255, 0.99) 44.31%, rgba(255, 255, 255, 0) 113.74%);
}

.is-collapsed .collpase-button-container {
  display: flex;
}

.coupon-detail-code{
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #8B8E96;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 24px;
  border-bottom: 1px dashed #979797;
  word-break: break-all;
  display: flex;
  justify-content: center;
  align-items: center;
  span{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
.is-vue-desktop .coupon-detail-button-container{
  .coupon-detail-button.copy-codes{
    margin-top: 16px;
    margin-bottom: 15px;
    margin-left: 25px;
    margin-right: 25px;
    border-radius: 41px;
  }
  .coupon-detail-button.copy-code-single{
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .coupon-detail-button.shop-now{
    margin-top: 0px;
    border: 2px solid #E2002C;
    background: #FFFFFF;
    color: #E2002C;
    margin-left: 25px;
    margin-right: 25px;
    margin-bottom: 0px;
    border-radius: 41px;
    margin-bottom: 15px;
  }
}

.coupon-detail-code-contain{
  padding-left: 16px;
  padding-right: 16px;
  margin-left: -16px;
  margin-right: -16px;
}

.coupon-detail-mobile-button-container, .coupon-detail-button-container {
  background-color: #fff;
  margin-top: 12px;

  .coupon-detail-mobile-button-tip {
    background: #E8E8E8;
    padding: 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
  }

  .coupon-detail-button-tip{
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    background: #E8E8E8;
    padding: 16px;
    color: #000000;
  }

  .coupon-detail-mobile-use-button-text, .coupon-detail-mobile-copy-button-text {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .coupon-detail-mobile-use-button-text .coupon-unavailable {
    line-height: 20px;
    opacity: 0.3;
    padding: 4px 8px;
  }

  .coupon-detail-mobile-code, .coupon-detail-code-contain {
    border-bottom: 1px dashed #979797;
    color: #8B8E96;
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 16px;
    padding-right: 16px;
    word-break: break-all;
    span{
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .code{
      background: #E6E9F3;
      padding-left: 35px;
      padding-right: 35px;
      padding-top: 14px;
      padding-bottom: 14px;
      border: 1px dashed #05218A;
      font-style: normal;
      font-weight: 700;
      color: #000000;
      border-radius: 5px;
    }
  }

  .coupon-detail-mobile-code.mobile{
    border-bottom: unset;
    padding-bottom: 0px;
  }

  .coupon-detail-mobile-button, .coupon-detail-button {
    cursor: pointer;
    background: #E2002C;
    border-radius: 24px;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: bold;
    height: 48px;
    text-align: center;
    line-height: 48px;
    margin: 20px 24px;
  }
}

.coupon-detail-mobile-copy-container {
  margin-left: 14px;
  margin-right: 14px;
}

.coupon-detail-available {
  margin-top: 12px;
  font-size: 12px;
  color: #E2002C;
  line-height: 16px;
  img {
    height: 27px;
    width: 27px;
    margin-right: 8px;
  }
}

.see-more-button {
  color: #E2002C;
  padding-bottom: 30px;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  cursor: pointer;
}

.coupon-detail-container {
  background-color: #E5E5E5;
}

.brand-container-pointer{
  cursor: pointer;
}

.brand-row {

  display: flex;
  justify-content: space-between;
  align-items: center;

  .brand-logo {
    height: 60px;
    width: 60px;
    border-radius: 60px;
    background-size: contain;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.151891);
    flex: none;
  }

  .brand-container {
    display:flex;
    align-items: center;
  }

  .brand-name {
    margin-left: 13px;
    color: #000;
;
  }
}

.usage-block .usage-title:nth-child(n+2){
  margin-top: 30px;
}

.usage-block .usage-title{
  color: #05218A;
}

.coupon-applicable-stores .brand-name{
  color: #05218A;
}

.label-icon{
  background-position: center;
  background-size: cover;
  flex: none;
}

.is-vue-mobile, .is-vue-desktop{
  .coupon-descripton.voucher{
    background-color: rgba(227, 111, 0, 0.1);
  }

  .coupon-descripton.gift{
    background-color: rgba(127, 212, 197, 0.1);
  }
}

.queue-error{
    .queue-error-title{
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      color: #000000;
      margin-top: 30px;
      padding-left: 33px;
      padding-right: 33px;
    }
    .queue-error-content{
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: #000000;
      margin-top: 16px;
      padding-left: 24px;
      padding-right: 24px;
    }
    .queue-error-bottom{
      display: flex;
      justify-content: center;
      margin: 24px;
      .button{
        padding: 8px 30px 10px;
        background: #E2002C;
        border-radius: 50px;
        gap: 10px;
        color: #FFFFFF;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        border: none;
      }
    }
}

.coupon-descripton {
    // margin-left: 21px;
    // margin-right: 21px;
    padding: 20px;
    background-color: #e1e6f0;
    margin-bottom: 20px;
    color: #000000;
    font-size: 15px;
    word-wrap: break-word;
  }

  .coupon-description-title {
    font-weight: 600;
  }

  .coupon-description-content {
    font-weight: 400;
    white-space: normal;
  }

.campaign-tag{
  display: flex;
  padding-top: 16px;
  padding-left: 27px;
  margin-bottom: -10px;
  .tag{
    display: flex;
    align-items: center;
    padding: 8px;
    font-weight: 600;
    font-size: 10px;
    // color: #F29B19;
    background-color: #fff;
    // border: 1.5px solid #F29B19;
    border: 1px solid hsla(0, 0%, 40%, 1);
    color: hsla(0, 0%, 40%, 1);
    box-sizing: border-box;
    border-radius: 3px;
    height: 27px;
    width: fit-content;
    // text-transform: uppercase;
  }
  // .gift.tag{
  //   color: #4875AA;
  //   border: 1.5px solid #4875AA;
  // }
  .tag.starred{
    border: 1.5px solid #FF580A;
    color: #FF580A;
    margin-right: 8px;
  }
  // .tag.ticket{
  //   color: #0C9B81;
  //   border: 1.5px solid #0C9B81;
  // }
}

.coupon-block .coupon-indicator {
  display: flex;
  padding: 20px 16px;
  align-items: center;
  flex-shrink: 0;

  color: #FFF;
  font-family: Open Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 19.5px */
  /*text-transform: uppercase;*/
  min-height: 59.5px
}

.is-vue-desktop {
  .starred-full{
    font-weight: 600;
    font-size: 13px;
    color: #999999;
    margin-bottom: 16px;
    line-height: 18px;
  }
  .add-to-wallet.starred-full{
    margin-top: 12px;
    margin-bottom: 0px;
  }
  .coupon-description-content.toastui-editor-contents{
    font-size: 15px;
  }
  .tour-carousel .tour-card-center {
    text-align: center;
  }
  .slide-image {
    padding-top: calc(3/4 * 100%);
  }

  .coupon-block.tours-card-block {
    padding-bottom: 70px;
  }
  .sticky-coupon-footer {
    display: none;
  }

  .is-collapsed {
    max-height: 400px;
  }

  .coupon-block {
    background-color: #f7f7f7
  }

  .document-campaign-detail .coupon-title, .document-coupon-detail .coupon-title {
    margin-top: 30px;
    margin-left: 27px;
    margin-right: 27px;
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
    margin-bottom: 20px;
    // min-height: 71px;;
    overflow-y: unset;
    height: unset;
    color: #000;
  }

  .coupon-expiration {
    margin-left: 30px;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #999999;
  }

  .reference-id-contain{
    margin-left: 30px;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    justify-content: left;
    color: #898989;
  }

  .brand-row {

    margin-left: 30px;
    margin-right: 39px;

    .brand-name {
      margin-left: 13px;
      font-weight: bold;
      font-size: 20px;
      line-height: 25px;
    }
  }

  .usage-block {
    padding-left:21px;
    padding-right:21px;
    padding-top: 30px;
    word-wrap: break-word;
  }

  .usage-title {
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 12px;
  }

  .usage-description{
    margin-bottom: 30px;
    font-size: 15px;
    line-height: 23px;
  }

  .coupon-detail-container {
    padding-left: 37px;
    padding-right: 34px;
    padding-top: 40px;
    padding-bottom: 30px;
    display:flex;
    // align-items: flex-start;
  }
  .coupon-detail-column {
    width: 0px;
    flex: 1;
    margin-right: 22px;
  }

  .coupon-block {
    margin-bottom: 11px;
    padding-bottom: 20px;
  }

  .coupon-block .coupon-indicator {
    display: flex;
    padding: 20px 16px;
    align-items: center;
    flex-shrink: 0;

    color: #FFF;
    font-family: Open Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 19.5px */
    /*text-transform: uppercase;*/
    min-height: 59.5px
  }

  .qr-code-column {
    width: 310px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .qr-container {
    display: flex;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 19px;
    padding-bottom: 18px;
    background-color: #fff;
    border: 2px solid #FE4534;
  }

  .qr-container:hover{
    opacity: 0.54;
  }

  .qr-ticket-container {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 18px;
    padding-bottom: 18px;
    background-color: #fff;
    border: 2px solid #FE4534;
  }

  .qr-code{
    width:70px;
    height: 70px;
  }

  .qr-description {
    width: 190px;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    margin-left: 9px;
    color: #000;
  }

  .label-row {
    display:flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .label-row.no-logo-label-url{
    cursor: default;
  }

  .label-row:last-child {
    margin-bottom: 0;

  }

  .coupon-detail-copy-icon {
    width: 16px;
    height: 16px;
    margin-left: 10px;
    cursor: pointer;
  }

  .coupon-detail-code-contain{
    border-bottom: none;
    padding-bottom: 0px;
  }

  .coupon-redeem-tip-container {
    margin-top: 23px;
    margin-bottom: 23px;
    margin-left: 20px;

    .coupon-redeem-tip {
      background: #E2002C;
      border-radius: 3px;
      color: #FFFFFF;
      font-size: 15px;
      padding: 3px 10px;
      font-weight: bold;
      text-transform: uppercase;
    }
    .coupon-redeem-tip{
      background-color: #A6A6A6;
    }

  }

  .label-icon {
    width: 63px;
    height: 63px;
    margin-left: 20px;
  }

  .label-description{
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    width: 200px;
    margin-left: 10px;
    word-wrap: break-word;
    color: #000;
  }

  .label-container {
    margin-bottom: 20px;
    background-color: #fff;
    padding-top: 20px;
    padding-bottom: 20px
  }

  .back-to-top{
    display: none;
  }

  .coupon-applicable-stores{
    padding: 21px;
    padding-right: 39px;
    height: 88px;
    font-size: 20px;
  }
  .qr-canvas-contain{
    display: none;
  }
  .goodie-bag-drawer{
    .goodie-bag-drawer-price{
      margin-left: -20px;
      margin-right: -20px;
      padding: 20px;
      padding-bottom: 7px;
      .line{
        .left{
          font-size: 15px;
          font-weight: 600;
        }
        .right{
          font-weight: bold;
          font-size: 20px;
        }
        .right.s-size{
          font-weight: 600;
          font-size: 18px;
        }
      }
      hr{
        margin-bottom: 20px;
      }
    }
    .goodie-bag-drawer-bottom{
      margin-top: 30px;
      margin-left: 40px;
      margin-right: 40px;
      .button{
        width: 100%;
        margin-left: 3px;
        margin-right: 3px;

        .white-arrow-right {
          margin-left: 2px;
        }
      }
    }
  }
  .goodie-bag-limit{
    font-weight: 600;
    line-height: 24px;
    font-size: 15px;
    color: #999999;
    padding-left: 30px;
    padding-right: 30px;
    .contain{
      display: flex;
      align-items: flex-start;
    }
    img{
      margin-right: 8px;
    }
    b{
      margin-left: 4px;
    }
    .limit-text{
      div{
        margin-bottom: 4px;
      }
    }
  }
  .goodie-bag-credits-info{
    margin-bottom: 20px;
  }
  .loading-dot{
    width: calc(100% - 280px);
  }
  .shop-now-url{
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.4);
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 15px;
    padding-top: 15px;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-inline-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .copy-code-redeemed{
    margin-top: 0px;
  }
  .goodie-bag-sold-out-title{
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    text-align: left;
    color: #4A4A4A;
    font-weight: 700;
    font-size: 30px;
  }
  .goodie-bag-sold-out-content{
    color: #404040;
    text-align: left;
    margin-left: 30px;
    margin-right: 30px;
    font-weight: 400;
    font-size: 15px;
  }
  .goodie-bag-sold-out-bottom{
    justify-content: right;
    padding-right: 37px;
    .button{
      font-weight: 700;
      font-size: 20px;
      padding-left: 36px;
      padding-right: 36px;
    }
  }
}

.coupon-description-container{
  margin-top: 20px;
}
.is-vue-mobile {
  .starred-full{
    font-weight: 600;
    font-size: 14px;
    color: #999999;
    line-height: 19px;
    margin-top: 8px;
  }
  .campaign-tag{
    margin-bottom: 0px;
    padding-left: 14px;
    padding-top: 16px;
  }
  .is-hidden-mobile {
    display: none;
  }
  .main-container .low-label{
    height: 30px;
    padding-left: 8px;
    padding-right: 8px;
    div{
      font-size: 16px;
    }
  }
  .qrcode-successfully-redeemed-suffix{
    margin-top: 16px;
  }
  .web-get-coupon-container{
    margin-bottom: 0px;
    .web-get-coupon-button{
      margin-top: 0px;
      width: 100%;
    }
    .landing-page{
      width: 100%;
    }
  }
  .usage-detail{
    font-size: 14px;
  }
  .coupon-detail-available{
    display: none;
  }
  .slide-image {
    padding-top: calc(3/4 * 100%);
  }
  .coupon-block.tours-card-block {
    padding-bottom: 52px;
  }
  .coupon-block {
    background-color:  #f2f3f6;
  }
  .coupon-block .coupon-indicator {
    display: flex;
    padding: 18px 27px;
    align-items: center;
    flex-shrink: 0;

    color: #FFF;
    font-family: Open Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 19.5px */
    /*text-transform: uppercase;*/
    min-height: 55.5px
  }
  .special-use-coupon {
    margin-top: 13px;
    margin-bottom: 12px;
    padding-left: 16px;
    padding-top: 14px;
    padding-bottom: 16px;
    box-shadow: 0px 2px 4px rgba(204, 204, 204, 0.5);
  }
  .goodie-bag-limit{
    font-weight: 600;
    font-size: 14px;
    color: #999999;
    padding-left: 14px;
    padding-right: 14px;
    line-height: 19px;
    .contain{
      display: flex;
      align-items: flex-start;
    }
    img{
      margin-right: 8px;
    }
    b{
      margin-left: 4px;
    }
    .limit-text{
      div{
        margin-bottom: 4px;
      }
    }
  }
  .special-use-coupon-tip {
    font-size: 14px;
    line-height: 22.5px;
  }
  .special-use-coupon-info {
    font-size: 14px;
    font-weight: 600;
    line-height: 19.07px;
  }
    .brand-description {
      margin-top: 15px;
      font-size: 14px;
    }
    .share-container {
      background: #fff;
      padding-top: 12px;
      padding-bottom: 17px;
      text-align: center;
    }
  .coupon-redeem-tip-container {
      margin-top: 8.8px;
      margin-left: 14px;
      .coupon-redeem-tip {
        background: #E2002C;
        border-radius: 3px;
        color: #FFFFFF;
        font-size: 15px;
        padding: 3px 10px;
        font-weight: bold;
        text-transform: uppercase;
      }
      .coupon-redeem-tip{
      background-color: #A6A6A6;
    }
  }
  .el-dialog.coupon-al.scan-code-success-bar-codeert-bar-dialog {
    border-radius: 10px;
    .scan-code-success-bar-code {
      border: 0px;
      margin-top: 0px;
      padding-top: 86px;
      padding-bottom: 40px;
    }
    .scan-code-success-bar-code.qr-code{
      padding-top: 36px;
      padding-bottom: 25px;
    }
  }
  .wide-code .el-dialog.coupon-alert-bar-dialog .scan-code-success-bar-code{
    padding-bottom: 34px;
  }
  .wide-code .scan-code-success-bar-code{
    padding-bottom: 30px;
  }
    .coupon-detail-mobile-button-container {
      margin-top: 0px;
      width: 100%;
    }
    .coupon-detail-mobile-copy-button-text{
      padding-bottom: 14px;
    }
    .sticky-footer-take-survey-button {
      width: 100%;
      font-weight: bold;
      font-size: 18px;
      line-height: 48px;
      text-align: center;
      color: #FFFFFF;
      background: #E2002C;
      border-radius: 41px;
      height: 48px;
      margin: auto;
    }
    .sticky-footer-view-code-button {
      border: 1px solid #E2002C;
      background: #fff;
      color: #E2002C;
      margin-top: 16px;
    }
    .coupon-detail-use-title {
      font-weight: bold;
      font-size: 20px;
      line-height: 27px;
      text-align: center;
      color: #2D2D2D;
    }
    .coupon-detail-use-button {
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
    }
    .coupon-use-confirm-dialog-content {
      font-size: 14px;
      text-align: center;
      color: #2D2D2D;
    }
    .coupon-detail-mobile-button {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      color: #E2002C;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      border: 2px solid #E2002C;
      box-sizing: border-box;
      border-radius: 41px;
      margin-left: 42px;
      margin-right: 42px;
      margin-top: 24px;
    }
    .coupon-detail-mobile-button.copy-code{
      background: #E2002C;
      color: #FFFFFF;
      margin-bottom: 10px;
    }
    .coupon-detail-mobile-button.shop-now{
      margin-top: 0px;
      margin-bottom: 10px;
    }
    .shop-now-url{
      font-weight: 400;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.4);
      text-align: center;
      margin-left: 42px;
      margin-right: 42px;
      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-inline-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .qr-canvas-contain{
      display: flex;
      flex-flow: column;
      align-items: center;
      box-shadow: 0px 2px 4px rgba(204, 204, 204, 0.5);
      margin-top: 16px;
      margin-left: 16px;
      margin-right: 16px;
      margin-bottom: 21px;
    }
    .qr-canvas-title{
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      background: #E8E8E8;
      padding-top: 12px;
      padding-bottom: 12px;
      width: 100%;
      text-align: center;
    }
    .qr-canvas-srk{
      background-color: #FFFFFF;
      font-weight: 600;
      line-height: 22px;
      color: #404040;
      text-align: center;
      padding-bottom: 16px;
      font-size: 16px;
    }
    .qr-canvas-footer{
      margin: 16px;
      color: #999999;
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
    }
    .goodie-bag-footer.sticky-coupon-footer{
      background-color: #F2F3F6;
    }
    .sticky-coupon-footer {
    width: 100%;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 14px;
    padding-bottom: 11px;
    border-top:  1px solid #D1D1D1;
    background-color: #fff;
    cursor: pointer;
    flex-direction: column;
    padding-left: 14px;
    padding-right: 14px;
    z-index: 10;
  }
  .mini .sticky-coupon-footer{
    bottom: calc(66px + var(--sab));
  }
  .sticky-coupon-footer-button {
    display: flex;
  }
  .sticky-coupon-footer-survey-buttons {
    flex-direction: column;
  }
  .sticky-footer-icon {
    flex: 0 0 30px;
    height: 30px;
    margin-left: 12px;
    margin-right: 6.5px;
  }
  .sticky-footer-button {
    flex: 0 0 154px;
    height: 48px;
    background-color: #E2002C;
    border-radius: 41px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    /* identical to box height */
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sticky-footer-button.get-coupon{
    flex: auto;
    height: 48px;
  }
  .sticky-footer-description {
    font-size: 12px;
    line-height: 18px;
  }
  .sticky-footer-button.coupon-unavailable, .coupon-unavailable .sticky-footer-button{
    background: rgba(226, 0, 44, 0.3);
    cursor: default;
  }
  .sticky-coupon-footer-button{
    width: 100%;
  }
  .sticky-footer-button{
    flex: unset;
    width: 100%;
  }
  .is-collapsed {
    max-height: 350px;
  }
  .sticky-campagin-button {
    justify-content: center;
  }
  .document-campaign-detail .coupon-title, .document-coupon-detail .coupon-title {
    margin-top: 20px;
    margin-left: 14px;
    margin-right: 14px;
    font-weight: bold;
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 20px;
    // min-height: 57px;
    height: unset;
    overflow-y:unset;
    color: #000;
  }
  .coupon-expiration {
    margin-left: 16px;
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #898989;
  }
  .reference-id-contain{
    margin-left: 16px;
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    justify-content: left;
    color: #898989;
  }
  .brand-row {
    margin-top: 10px;
    margin-left: 14px;
    margin-right: 14px;
    // margin-bottom: 20px;
    .brand-name {
      margin-left: 12px;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
    }
  }
  .coupon-descripton {
    // margin-left: 14px;
    // margin-right: 14px;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 13px;
    background-color: #e1e6f0;
    font-size: 14px;
    line-height: 22px;
    .toastui-editor-contents{
      font-size: 14px;
    }
  }
  .coupon-block {
    margin-bottom: 8px;
    padding-bottom: 20px;
  }
  .usage-block {
    padding-left:14px;
    padding-right:14px;
    padding-top: 24px;
    word-wrap: break-word;
  }
  .usage-title {
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 12px;
  }
  .usage-description{
    margin-bottom: 14px;
    font-size: 14px;
    line-height: 23px;
  }
  .label-row {
    display:flex;
    align-items: center;
    padding-bottom: 12px;
  }
  .label-row.no-logo-label-url{
    cursor: default;
  }
  .label-icon {
    width: 63px;
    height: 63px;
    margin-left: 12px;
  }
  .label-description{
    font-size: 14px;
    line-height: 19px;
    margin-left: 12px;
    word-wrap: break-word;
    color: #404040;
  }
  .label-container {
    background-color: #fff;
    padding-top: 20px;
    padding-bottom: 20px
  }
  .back-to-top {
    padding-right : 16px;
    padding-top: 8px;
    padding-bottom: 16px
  }
  .coupon-applicable-stores{
    padding: 14px;
    height: 65px;
    font-size: 20px;
  }
  .goodie-bag-footer{
    cursor: unset;
    border-top: none;
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 24px;
    z-index: 100;
    .require{
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 20px;
      .left{
        .title{
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 4px;
          line-height: 19px;
        }
        .num{
          font-weight: 700;
          font-size: 22px;
          color: #FF5C00;
          line-height: 29.96px;
        }
      }
      .right{
        display: flex;
        .reduce, .add{
          border: 1.5px solid #E2002C;
          height: 34px;
          width: 34px;
          border-radius: 17px;
          color: #E2002C;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 100;
          cursor: pointer;
          user-select: none;
        }
        .add{
          font-size: 28px;
          margin-left: 12px;
        }
        .reduce.disable, .add.disable{
          border: 1.5px solid #C2C2C2;
          color: #C2C2C2;
          cursor: unset;
        }
        .reduce{
          font-size: 28px;
          padding-bottom: 5px;
          margin-right: 12px;
        }
        .count{
          font-weight: bold;
          font-size: 22px;
        }
      }
    }
    .sticky-footer-button{
      cursor: pointer;
    }
  }
  .goodie-bag-footer-single{
    display: flex;
    // flex-flow: row;
    z-index: 100;
    .goodie-bag-footer-single-top{
      display: flex;
      width: 100%;
      align-items: center;
    }
    .left{
      width: 250px;
      .title{
        font-weight: 600;
        font-size: 14px;
        color: #000000;
        margin-bottom: 5px;
      }
      .num{
        font-weight: bold;
        font-size: 22px;
        color: #FF5C00;
      }
    }
    .left.goodie-bag-footer-gift .title{
      margin-bottom: 8px;
    }
    .left.goodie-bag-footer-gift .title.single{
      margin-bottom: 0px;
    }
  }
  .goodie-bag-footer-single.gift{
    padding: 16px;
    flex-flow: column;
  }
  .goodie-bag-footer-single .sticky-footer-button{
    width: calc(100% - 70px);
    padding: 10px;
  }
}
.goodie-bag-drawer{
  .goodie-bag-drawer-img{
    width: 45%;
    padding-top: calc(3/4 * (45% - 16px));
    margin-right: 16px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .goodie-bag-drawer-body{
    display: flex;
    flex-flow: row;
    align-items: normal;
    .body-title{
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      width: calc(55% - 16px);
      .title{
        font-weight: 600;
        font-size: 18px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        color: #000000;
        margin-bottom: 0px;
        line-height: 25px;
      }
      .type{
        color: #2D2D2D;
        font-size: 14px;
        line-height: 19px;
      }
    }
  }
  .goodie-bag-drawer-price{
    background: #EEEEEE;
    margin-left: -16px;
    margin-right: -16px;
    margin-top: 28px;
    padding: 16px;
    padding-bottom: 4px;
    hr{
      height: 1px;
      margin-top: 2px;
      margin-bottom: 12px;
      background-color: #CCCCCC;
    }
    .line{
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
      .left{
        font-size: 14px;
        color: #000000;
        line-height: 22px;
      }
      .right{
        font-size: 16px;
        color: #000000;
        font-weight: 600;
        line-height: 22px;
      }
      .price{
        font-weight: bold;
        font-size: 18px;
        color: #FF5C00;
      }
      .price.gift{
        color: #4875AA;
      }
      }
  }
  .goodie-bag-drawer-bottom{
    margin-top: 24px;
    display: flex;
    flex-flow: row-reverse;
    margin-bottom: 24px;
    .button{
      height: 43px;
      font-weight: bold;
      font-size: 18px;
      border-radius: 24px;
      padding-left: 26px;
      padding-right: 26px;
      border:none;
      min-width: 148px;
      .white-arrow-right {
        margin-left: 2px;
      }
    }
    .button.loading{
      padding-left: 0px;
      background: #E2002C;
      color: #FFFFFF;
      padding-right: 0px;
    }
  }
  .goodie-bag-reminder{
    color:#999999;
    margin-bottom: 16px;
    font-size:14px;
    line-height:19px;
    font-weight: 600;
  }

}

.applicable-stores-dialog-title{
  color: #05218A;
  font-weight: bold;
  font-size: 20px;
}

.back-img{
  height: 40px;
  width: 40px;
  cursor: pointer;
}

.coupon-applicable-stores{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  cursor: pointer;
}

.scan-code-success-img{
  width: 134px;
  height: 120px;
}

.scan-code-success-check{
  margin-bottom: 20px;  /* 44px - 16px */
  margin-top: 0;
  color: #04BD81;
  font-size: 80px;
  font-weight: 300;
  line-height: 108.95px;
  font-family: Open Sans, Microsoft JHei TC Regular, arial, sans-serif;
}

.scan-code-success-dialog-content{
  display: flex;
  align-items: center;
  flex-flow: column;
  .rotation-reminder{
    padding-left: 16px;
    padding-right: 16px;
    justify-content: space-evenly;
    display: flex;
    flex-flow: row;
    color: #999;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    margin-top: 8px;
    align-items: center;
    img {
      height: 30px;
      margin-right: 5px;
    }
  }
}

.el-dialog__body .scan-code-success-dialog-content{
  .reference-id-contain{
    color: #999999;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 8px;
    margin-left: 0px;
  }

  .scan-code-success-description{
    font-weight: 600;
    font-size: 16px;
    color: #999999;
    margin-bottom: 11px;
  }

  .scan-code-success-name{
    text-align: center;
    font-weight: bold;
    font-size: 21px;
    color: #000000;
    word-wrap: break-word;
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 8px;
  }

  .scan-code-success-message{
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
    // word-break: keep-all;
    word-wrap: break-word;
    padding-left: 24px;
    padding-right: 24px;
  }

  .scan-code-success-message.scan-code-store{
    margin-bottom: 8px;
  }

  .dialog-footer .scan-code-success-done{
    background: #E2002C;
    border-radius: 41px;
    width: 150px;
  }

  .coupon-content .coupon-type{
    font-size: 16px;
    padding: 4px 8px;
    // margin: 0;
  }

  .scan-code-success-buttons {
    width: 100%;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 16px;
    padding-bottom: 6px;
    position: fixed;
    bottom: 0;
    z-index: 50;
    background-color: #fff;
    box-shadow: 0px 1px 0px 0px #D1D1D1 inset;

    .scan-code-success-done-button, .scan-code-success-take-survey {
      border-radius: 50px;
      background: #E2002C;
      text-align: center;
      cursor: pointer;
      display: flex;
      width: 100%;
      height: 48px;
      padding: 8px 40px;
      margin-bottom: 8px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;

      color: #FFF;
      text-align: center;

      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 116%; /* 20.88px */
    }

  }

  .scan-code-success-buttons.has-survey .scan-code-success-done-button{
    color: #E2002C;
    background: #fff;
  }

  .scan-code-success-take-survey {
    width: 190px;
    height: 48px;
    margin-bottom: 16px;
    background: #E2002C;
    border-radius: 41px;
    color: #fff;
    text-align: center;
    line-height: 48px;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
  }

  .scan-code-success-bar-code {
    // padding: 27px 46px;
    width: 100%;
    padding-bottom: 8px;
    margin-top: 20px;
    padding-top: 20px;
    text-align: center;

    .partner-qr-bar-code-code {
      color: #000000;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      word-break: break-all;
      padding: 0 20px 10px;
    }
  }

  .scan-code-success-bar-footer {
    word-break: normal;
    font-weight: 600;
    font-size: 14px;
    color: #000;
    background: #EEE;
    padding: 12px 16px;
    text-align: left;
  }

}

.scan-code-success-dialog .el-dialog__body .scan-code-success-dialog-content {
  .scan-code-success-bar-code {
    .partner-qr-bar-code-code {
      padding: 0 40px 10px;
    }
  }
}

.el-dialog.full-dialog .el-dialog__body .scan-code-success-dialog-content{
 .scan-code-success-bar-footer {
    margin-bottom: 150px;
  }

  .scan-code-success-bar-code {
      border-top: 1px dashed #979797;
  }
}

.qrcode-error-message{
  font-size: 14px;
  color: #2D2D2D;
  // word-break: keep-all;
  word-wrap: break-word;
}
.el-dialog.el-dialog--center{
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}
.v-modal{
  z-index: 0 !important;
}
.coupon-detail-container #qr-shaded-region{
  display: none;
}
#scan-qr-code{
  z-index: 999;

  .qr-code-full-window {
    position: fixed !important;
    margin: 0;
    left: 0;
    top: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: #7B7B7B;
    display: flex;
    align-items: center;
    flex-flow: column;
  }
  #qr-code-full-region {
    width: 100%;
    position: fixed !important;
    bottom: -15px;
  }
  .full-width{
    width: 100%;
  }
  .close-contain{
    justify-content: flex-end;
    display: flex;
    margin-bottom: -18px;
  }
  .close-contain img{
    height: 40px;
  }
  .close{
    float: right;
    margin-right: 21px;
    margin-top: 19px;
  }
  .title-contain{
    font-weight: bold;
    font-size: 22px;
    color: #FFFFFF;
    padding-left: 28px;
  }
  .description-contain{
    font-size: 14px;
    line-height: 22px;
    color: #FFFFFF;
    padding-left: 28px;
    padding-right: 28px;
    margin-top: 12px;
  }
  .qr-contain{
    z-index: 40;
  }
}

.el-loading-mask.is-fullscreen{
  background: #F7F7F7;
}

.alert-mobile-qrcode {
  text-align: center;
}
.alert-mobile-qrcode-content {
  word-break: normal;
  font-size: 15px;
  line-height: 24px;
  color: #000000;
  font-weight: 600;
}

.alert-mobile-back-icon {
  position: fixed;
  background-size: contain;
  width: 44px;
  height: 44px;
  left: 33px;
  top: 33px;
}
.goodie-bag-sold-out-title{
  font-weight: 700;
  font-size: 18px;
  padding-top: 43px;
  padding-left: 33px;
  padding-right: 33px;
  color: #000000;
}
.goodie-bag-sold-out-content{
  font-size: 14px;
  color: #000000;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 16px;
  text-align: center;
  word-break: break-word;
  font-weight: 400;
}
.goodie-bag-sold-out-bottom{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 18px;
  margin-bottom: 28px;
  .button{
    border-radius: 50px;
    border:none;
    font-weight: 700;
    font-size: 18px;
    padding-left: 40px;
    padding-right: 40px;
  }
}
.loading-dot{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 41px;
  width: calc(100% - 60px);
}
.carousel-banner.goodie-bag{
  padding-top: 16px;
  padding-left: 14px;
  padding-right: 14px;
}
.reference-id-contain{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
</style>

<style lang='scss'>

#qr-code-full-region {
  #qr-shaded-region {
    div {
      background-color: #E2002C !important;
    }
  }
}

.document-campaign-detail .el-dialog, .document-coupon-detail .el-dialog{
  overflow: auto;
  margin-top: auto;
  margin-bottom: auto;
}
.document-campaign-detail.has-one-trust .el-dialog,.document-coupon-detail.has-one-trust .el-dialog{
  margin-top: calc(40vh - 100px);

}
.document-coupon-detail.has-one-trust .el-dialog.scan-code-success-dialog,.document-campaign-detail.has-one-trust .coupon-alert-bar-dialog{
  // height:60%;
  // overflow-y: scroll;
  margin-top: calc(20vh - 100px);
}
.document-coupon-detail.has-one-trust .el-dialog.scan-code-success-dialog{
  margin-top: calc(20vh - 100px);
}

.mobile-qrcode-dialog {

  .el-dialog__header {
    padding: 0px;
  }

  .el-dialog--center .el-dialog__body {
    padding-left: 67px;
    padding-right: 67px;
    padding-top: 50px;
    padding-bottom: 40px;
  }
}

.scan-code-success-dialog.el-dialog--center, .coupon-alert-bar-dialog.el-dialog--center{
  border-radius: 10px;
  .el-dialog__body {
    padding: 0px;
  }
}

.wide-code .scan-code-success-dialog, .wide-code .coupon-alert-bar-dialog{
  width: calc(100% - 6px) !important;
  .scan-code-success-bar-footer{
    width: 100%;
    text-align: center;
  }
}

.el-dialog.coupon-alert-bar-dialog .el-dialog__header{
  padding: 0px;
}

.coupon-use-confirm-dialog {
  .el-dialog__body {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 0px;
    padding-bottom: 30px;
  }

  .el-dialog__header {
    padding-left: 30px;
    padding-top: 15px;
  }

}

.document-campaign-detail .el-dialog__wrapper, .document-coupon-detail .el-dialog__wrapper{
  display: flex;
  align-items: center;
}

.el-dialog.coupon-use-confirm-dialog, .el-dialog.coupon-alert-bar-dialog{
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  overflow: hidden;
}
.applicable-stores-dialog .el-dialog__header{
  padding-left: 30px;
}
.applicable-stores-dialog .el-dialog__close{
  font-size: 32px;
}
.applicable-stores-dialog .el-dialog__body{
  padding: 10px;
  padding-bottom: 10px;
}

.get-coupe-icon-loading{
  background-image: url('/img/loading.gif');
  background-size: contain;
  width: 120px;
  height: 120px;
  left: calc(50% - 60px);
  top: calc(50% - 60px);
  position: absolute;
}

.carousel.detail .agile__nav-button.agile__nav-button--prev img{
  margin-left: 12px;
}

.carousel.detail .agile__nav-button.agile__nav-button--next img{
  margin-right: 12px;
}

.carousel.detail .agile__actions{
  height: 2px;
}

.scan-code-success-dialog .el-dialog__headerbtn i{
  font-size: 28px;
}

.scan-code-success-dialog .el-dialog__body{
  padding-bottom: 22px;
}

.is-vue-mobile .scan-code-success-dialog .coupon-indicator {
  width: 100%;
  display: flex;
  padding: 20px 16px;
  align-items: center;
  flex-shrink: 0;

  color: #FFF;
  font-family: Open Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 19.5px */
  /*text-transform: uppercase;*/
  min-height: 55.5px
}

.is-vue-desktop .scan-code-success-dialog .coupon-indicator {
  width: 100%;
  display: flex;
  padding: 18px 27px;
  align-items: center;
  flex-shrink: 0;

  color: #FFF;
  font-family: Open Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 19.5px */
  /*text-transform: uppercase;*/
  min-height: 59.5px
}

.el-dialog.full-dialog.barcode-full-screen {
  z-index: 999;

  .el-dialog__body{
    height: 100%;
    width: 100%;
  }

  .barcode-full-screen-content, .full-barcode-content {
    width: 100%;
    height:100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}

.el-dialog.full-dialog{
  width: 100% !important;
  height: 100% !important;
  margin-top: 0 !important;
  border-radius: 0 !important;

  .el-dialog__footer {
    width:100%;
    text-align: center;
    position:absolute;
    bottom:0;
  }

  .el-dialog__header{
    height: 0;
    padding-top:0;
    padding-bottom:0;
  }
  .el-dialog__close {
    display: none;
  }
}

.is-vue-desktop .goodie-bag-drawer{
  .el-drawer{
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    width: 500px !important;
  }
  .el-drawer__header{
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    margin-bottom: 30px;
    span{
      font-size: 30px;
    }
  }
  .el-drawer__body{
    padding-left: 20px;
    padding-right: 20px;
    .body-title{
      .title{
        font-weight: bold;
      }
    }
  }
}

.goodie-bag-drawer{
  .el-drawer{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: unset !important;
  }
  .el-drawer__header{
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
    margin-bottom: 20px;
    line-height: 30px;
    span{
      font-weight: bold;
      font-size: 22px;
      color: #000000;
    }
    i{
      font-size: 30px;
    }
    i::before{
      color: #999999;
    }
  }
  .el-drawer__body{
    padding-left: 16px;
    padding-right: 16px;
  }
  .el-drawer__close-btn{
    padding-right: 0px;
  }
}
.carousel-banner.goodie-bag .agile__list{
  border-radius: 5px;
}

.el-dialog__wrapper.recaptcha-fail {
  .goodie-bag-sold-out-title {
    text-align: center;
  }
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
}

.el-dialog__wrapper.recaptcha-fail .el-dialog__close.el-icon.el-icon-close{
  font-size: 30px;
}

.el-dialog__wrapper.goodie-bag-fail .el-dialog__close.el-icon.el-icon-close{
  font-size: 30px;
}

.fail-to-acquire-coupon {
  [role="dialog"] {
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    .el-dialog__header {
      padding-top: 30px;
      padding-bottom: 0;
      .el-dialog__title {
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        color: #000000;
      }
    }
    .el-dialog__body {
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
      justify-content: center;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: #000000;
    }
    .el-dialog__footer {
      padding-top: 24px;
      padding-bottom: 24px;
      .el-button--primary {
        padding-top: 8px;
        padding-bottom: 10px;
        padding-left: 39.5px;
        padding-right: 39.5px;
        border-radius: 50px;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
      }
    }
  }
}
.coupon-campaign-reminder{
  .coupon-campaign-reminder-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #4A4A4A;
    margin-top: 32px;
  }
  .coupon-campaign-reminder-content {
    // margin-top: 28px;
    // padding-left: 30px;
    // padding-right: 30px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #404040;
    margin-top: 20px;
    padding-left: 18px;
    padding-right: 18px;
  }
  .coupon-campaign-reminder-bottom {
    text-align: center;
    margin: 28px;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    .button{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 8px 30px 10px;
      gap:10px;
      border-radius: 50px;
      margin-left: 24.5px;
      margin-right: 24.5px;
    }
    .ok{
      background-color: #E2002C;
      margin-bottom: 16px;
    }
  }
}
.partner-qr-bar-code{
  .partner-qr-bar-code-code {
    color: #000000;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    word-break: break-all;
    padding: 0 16px;
  }
  .partner-qr-bar-code-reference-id{
    color: #404040;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
  }
}

.is-vue-mobile .partner-qr-bar-code{
  .coupon-detail-mobile-button-tip{
    color: #000;
    font-size: 14px;
    font-weight: 600;
  }
  .coupon-detail-mobile-button-text .coupon-detail-mobile-copy-button-text {
    padding-bottom: 16px;
  }
  .coupon-detail-mobile-copy-button-text .coupon-detail-mobile-code{
    padding: 0;
    margin: 0;
  }
  .coupon-detail-mobile-code.mobile.partner-bar-code{
    display: block;
    text-align: center;
    margin: 16px;
    overflow-x: hidden;
    margin-top: 6px;
  }
  .partner-bar-code .rotation-reminder{
    display: flex;
    align-items: center;
    margin-left: 14px;
    margin-right: 14px;
    font-size: 14px;
    color: #999;
    font-weight: 400;
    text-align: left;
    word-break: normal;
  }
  .partner-bar-code .rotation-reminder img{
    height: 30px;
    width: 30px;
    margin-right: 4px;
  }
}
.coupon-detail-button-container .coupon-detail-button-tip.code-to-staff-text{
  color: #000;
    font-size: 14px;
    font-weight: 600;
}
.is-vue-desktop .partner-qr-bar-code{
  .coupon-detail-code-contain {
    padding-top: 0;
  }
  .coupon-partner-qr-code{
    padding-bottom: 0;
    border-bottom: none;
  }
  .partner-qr-bar-code-code {
    margin-bottom: 20px;
  }
  .partner-qr-bar-code-reference-id{
    margin-bottom: 20px;
  }
  .partner-bar-code{
    margin: 16px;
    overflow: hidden;
  }
  .partner-bar-code-tip {
    color: #000;
    font-size: 14px;
    font-weight: 600;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 20px;
  }
  .partner-bar-code-tip span{
    color: #E2002C;
    cursor: pointer;
    word-break: normal;
  }
}
.el-dialog.desktop-coupon-alert-bar-dialog{
  margin-top: auto !important;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.40);
  .el-dialog__body{
    padding-top: 0px;
  }
  .view-barcode-text{
    color: #4A4A4A;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  .barcode-full-screen-content, .full-barcode-content{
    text-align: center;
  }
  .el-icon-close:before{
    transform: scale(2);
    display: inline-block;
  }
}

.scan-code-success-dialog-content .coupon-content .coupon-type-container  {

  width: calc(100vw);
  padding-left: 24px;
  padding-right: 24px !important;

  .coupon-type-tags {
    display: flex;
    align-items: center;
    padding-right: 0px;

    .coupon-type.starred {
      margin-left: 0px;
    }
  }
}

</style>
